import './App.scss';
import { Routes } from './routes/Routes';
import './translations';
import ReactGA from 'react-ga';
import ScrollToTop from './shared';

const TRACKING_ID = '';
ReactGA.initialize(TRACKING_ID);

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Routes />
    </div>
  );
}

export default App;
