import React from 'react';
import Banner from '../components/Banner/Banner';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Heading from '../components/Heading/Heading';
import Box from '@mui/material/Box';
import Card from '../components/Card/Card';
import { useTranslation } from 'react-i18next';

import './IsLifeAGame.scss';

const Belief = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="banner">
        <Banner />
        <div className="banner-header">
          <img src={require('../assets/images/cfarebesojnemuslimanet.png')} />
        </div>
      </div>
      <section>
        <Box>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={3}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <h1 className="card-heading np">ÇFARË MUSLIMANËT BESOJNË</h1>
                  <p className="description pt">
                    Mesazhi themelor i Islamit është i thjeshtë: Duhet adhuruar
                    vetëm Zoti.Kurani (shkrimi i shenjtë i shpallur i Islamit)
                    thotë:
                    <span className="highlight">
                      “Thuaj(O Muhamed): ‘Ai është Allahu, Një dhe i Vetëm!
                      Allahu është Absoluti, të Cilit i përgjërohet gjithçka në
                      amshim. Ai as nuk lind, as nuk është i lindur. Dhe askush
                      nuk është i barabartë (a i krahasueshëm) me Atë!’” 112:1-4
                    </span>
                  </p>
                  <p className="description">
                    Pjesa e parë e dëshmisë së besimit të muslimanëve, formon
                    bazën për konceptin e Zotit në Islam. Muslimanët dëshmojnë
                    se: “Nuk meriton të adhurohet askush përveç Allahut Koncepti
                    i Zotit në Islam është monoteist dhe Unitarian. Zoti është
                    qenie absolute, tërësisht i pavarur dhe plotësisht i
                    vetëmjaftueshëm. Çdo gjë që ekziston apo që do të mund të
                    ekzistonte ndonjëherë, është nga vullneti i Tij.
                  </p>
                  <p className="description pt">
                    Ai nuk ka ortak në krijimin e gjithësisë e as në
                    mirëmbajtjen e ekzistencës së tij. Ai nuk është vetëm
                    “Shkaku i Parë” por është përfundimisht i Vetmi Shkak dhe Ai
                    është Vetë i pashkak.Pjesa e dytë e testamentit të besimit
                    është: “Muhamedi, paqja dhe lëvdatat qofshin mbi të, është i
                    Dërguari i fundit” .Muslimanët i mbajnë në shkallë të lartë
                    nderimi të gjithë profetët dhe të dërguarit e mëparshëm,
                    duke përfshirë Ademin (Adamin), Nuhun (Noen), Ibrahimin
                    (Abrahamin), Jakubin (Jakobin), Is’hakun (Isakun), Ismailin
                    (Ishmaelin), Musain (Mojsiun), Davudin (Davidin), Sulejmanin
                    (Solomonin), Isain (Jezusin) dhe shumë të tjerë, paqja qoftë
                    mbi të gjithë; të gjithë ia kanë nënshtruar dhe dorëzuar
                    veten vetëm një Zoti të vetëm, i Cili i krijoi dhe i zgjodhi
                    ata të jenë bartësit e mesazhit të Tij. Ashtu siç është
                    shpallur në Kuran:
                  </p>

                  <p className="description pt">
                    <span className="highlight">
                      “Thoni: ‘Ne besojmë në Allahun, në atë që na është
                      shpallur neve (Kuranin), në atë që i është shpallur
                      Ibrahimit, Ismailit, Is’hakut, Jakubit dhe Esbatëve (12
                      bijve të Jakubit), në atë që i është dhënë Musait e Isait
                      dhe në atë që u është dhënë profetëve nga Zoti i tyre. Ne
                      nuk bëjmë kurrfarë dallimi midis tyre dhe vetëm Allahut i
                      përulemi.” 2:136
                    </span>
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid container marginTop={'4%'} justifyContent="flex-end">
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description ">
                    Muhamedi, i Dërguari i fundit, Profeti i fundit dhe
                    përfundimtar i një zinxhiri të gjatë të profetëve dhe të
                    dërguarve ishte profeti Muhamed, paqja dhe lëvdatat qofshin
                    mbi të. Ai u dërgua në tokat Arabe, pra fliste gjuhën arabe.
                    Muhamedi, paqja dhe lëvdatat qofshin mbi të, u dërgua për të
                    riafirmuar mesazhin për të cilin të gjithë profetët e
                    mëparshëm ishin dërguar, d.m.th. për t’i thirrur njerëzit në
                    adhurimin e Zotit të vetëm.Majkëll Hart, në librin e tij
                    “Njëqindëshi: Renditja e personave më me ndikim në histori”
                    (The 100: a ranking of the most influential people in
                    histori) shkruan: “Zgjedhja ime që Muhamedi t’i prijë listës
                    së personave më me ndikim të botës, mund t’i habisë disa
                    lexues dhe mund të jetë pikëpyetjeje për disa të tjerë,
                    mirëpo ai ishte i vetmi njeri në histori, i cili kishte
                    arritur suksesin më të lartë në të dyja nivelet: atë fetar
                    dhe jofetar. Me një zanafillë modeste, Muhamedi themeloi dhe
                    përhapi një nga fetë më të mëdha të botës dhe u bë lider
                    politik jashtëzakonisht i dobishëm. Sot, trembëdhjetë shekuj
                    pas vdekjes së tij, ndikimi i tij është ende i fuqishëm dhe
                    i përhapur.”
                  </p>
                  <p className="description pt">
                    Profeti Muhamed, paqja dhe lëvdatat qofshin mbi të, si një
                    figurë e shquar, është universal, jo vetëm për shkak të
                    mesazhit të tij të barazisë, por për shkak të karakterit të
                    tij altruist (dashamirës, vetëmohues). Ai vazhdimisht i ka
                    ndihmuar të varfrit dhe kujtdo që kishte nevojë për ndihmë.
                    Mësimet e tij zbatohen në çdo kohë nga njerëzit e çdo pjese
                    të botës, qofshin ata të pasur apo të varfër. Ndër mësimet e
                    tij të urta ishte: “Mos e konsideroni asnjë nga veprat e
                    mira si të papeshë, qoftë edhe ta takosh vëllanë tënd me
                    fytyrë të gëzuar (buzëqeshur).” Muslimi: 2626 Historia në
                    vijim, na tregon për altruizmin e Profetit... Një grua e
                    vjetër e kishte zakon t’i hidhte mbeturina profetit Muhamed,
                    sa herë që ai kalonte pranë shtëpisë së saj. Kur gruaja ia
                    hidhte mbeturinat, ai kalonte në heshtje, pa treguar zemërim
                    apo bezdi dhe kjo ndodhte zakonisht.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description ">
                    Një ditë kur Profeti po kalonte pranë shtëpisë së saj,
                    gruaja nuk qëlloi aty për t’ia hedhur mbeturinat. Profeti
                    kërkoi leje për ta vizituar gruan; ai e kuptoi se ajo ishte
                    sëmurë. Ajo mendonte se ai kishte ardhur për të marrë hak
                    kur ajo nuk ishte në gjendje të vetëmbrohej. Mirëpo, Profeti
                    e siguroi atë se kishte ardhur vetëm për ta parë dhe për t’u
                    kujdesur për nevojat e saj, sepse ishte urdhër i Allahut se
                    nëse dikush është i sëmurë, muslimani duhet ta vizitojë dhe
                    ta ndihmojë atë në qoftë se ndihma e tij është e
                    nevojshme.Gruaja e vjetër u prek shumë nga kjo mirësi dhe
                    dashuri e Profetit. Me këtë shembull, ajo e kuptoi se ai
                    ishte me të vërtetë i Dërguar i Zotit dhe se Islami ishte
                    feja e vërtetë. Ajo më pas e pranoi Islamin.Në këtë mënyrë,
                    (para se Profeti të vdiste në moshën 63 vjeçare) shumica e
                    njerëzve të Arabisë e kishin pranuar mesazhin e Islamit dhe
                    në më pak se një shekull, mesazhi ishte përhapur në perëndim
                    deri në Spanjë e deri në Kinën e lindjes së largët.Në
                    pikëpamje Islame, mesazhi që u transmetua nga profeti
                    Muhamed, paqja dhe lëvdatat qofshin mbi të, përfaqësonte, jo
                    një fe krejtësisht të re, por një korrigjues për
                    falsifikimet dhe shtrembërimet që kishin zënë vend dhe
                    njëkohësisht një rimbrojtje të pakompromis të doktrinës së
                    pastër të Zotit të vetëm.
                  </p>
                  <p className="description pt">
                    Ashtu siç profetit Musa, Isa dhe Davud, paqja qoftë mbi ta,
                    u ishin dhënë libra të shpallur (Dhiata e Vjetër dhe e Re
                    dhe Psalmet; të cilat muslimanët i pranojnë) njëjtë edhe
                    profetit Muhamed, paqja dhe lëvdatat qofshin mbi të, i është
                    dhënë një i tillë. Mesazhi i sjellë nga profeti Muhamed,
                    paqja dhe lëvdatat qofshin mbi të, u konsolidua përmes
                    shpalljeve të njëpasnjëshme të njohura kolektivisht si
                    Kuran. Kurani përmban udhëzime për të gjithë njerëzimin dhe
                    ekziston edhe sot pikërisht në formën e njëjtë ashtu siç
                    ishte shpallur 1400 vite më parë.{' '}
                    <span className="highlight">
                      “O njerëz! Në të vërtetë, Ne ju krijuam ju prej një
                      mashkulli dhe një femre dhe ju bëmë popuj e fise, për ta
                      njohur njëri-tjetrin. Më i nderuari prej jush tek Allahu
                      është ai që i frikësohet më shumë Atij. Vërtet, Allahu
                      është i Gjithëdijshëm dhe për Atë asgjë nuk është e
                      fshehtë.” 49:13.
                    </span>{' '}
                    Kurani përmban udhëzime të cilat na mundësojnë të jetojmë
                    jetën në mënyrën që Zoti e do dhe është i kënaqur me të. Si
                    Krijues, Zoti na njeh më së miri, prandaj është më i
                    thirruri për të na udhëzuar se si të “funksionojmë mirë”.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={3}
              marginTop={'4%'}
              justifyContent="flex-end"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Imagjino sikur e ke blerë një iPhone; a do ta lexoje
                    doracakun udhëzues të prodhuar nga Sony Ericsson?
                    Logjikisht, jo! Arsyeja është fare e thjeshtë, kompania që
                    prodhon telefonin, e njeh produktin e saj brenda dhe jashtë
                    dhe kështu është më e thirrura që të shkruajë një doracak
                    udhëzues për përdoruesit e tij.E njëjta gjë është e vërtetë
                    me ne; Zoti na ka krijuar, prandaj Ai na njeh neve më mirë
                    se që ne mendojmë se e njohim vetveten. Prandaj, Kurani
                    është një “doracak udhëzues” nga Krijuesi për të krijuarin
                    (njeriun). Zoti thotë:
                  </p>
                  <p className="description pt">
                    <span className="highlight">
                      “Ky Kuran është tregues i qartë, udhërrëfyes dhe mëshirë
                      për njerëzit që kanë besim të patundur.” 45:20 Dhe në një
                      ajet tjetër thotë: “O njerëz, ju ka ardhur këshillë
                      (Kurani) nga Zoti juaj, shërim për zemrat tuaja, udhëzim
                      dhe mëshirë për besimtarët.” 10:57
                    </span>
                  </p>
                  <p className="description pt">
                    Muslimani/ja i gëzon dy marrëdhënie shumë të veçanta: 1. Një
                    marrëdhënie me Krijuesin e tij/saj. Kjo, në esencë, e forcon
                    besimin e njeriut dhe i mundëson atij të afrohet sa më shume
                    me Allahun, përmes angazhimit në adhurim. 2. Një marrëdhënie
                    me shoqërinë brenda të cilës ai/ajo jeton. Muslimanët kanë
                    përgjegjësi të mbështesin vlerat themelore të moralit si:
                    këshillimi në të mirë, ndalimi i të keqes, këshillimi për
                    drejtësi, përmbushja e premtimit, mirëqenia me fqinjët,
                    ndihma ndaj të pafatëve etj.
                  </p>

                  <p className="description pt">
                    Ka shumë ajete Kuranore që urdhërojnë për këto qëllime, për
                    shembull:{' '}
                    <span className="highlight">
                      “Mirësia nuk është të kthyerit e fytyrës suaj nga lindja
                      dhe perëndimi, por mirësia është (cilësi) e atij që beson
                      Allahun, Ditën e Fundit, engjëjt, Librin dhe profetët; e
                      atij që me vullnet jep nga pasuria e vet për të afërmit,
                      jetimët, të varfrit, udhëtarët e mbetur rrugës, lypësit
                      dhe për lirimin e të robëruarve; e atij që fal namazin dhe
                      e jep zekatin; dhe e atyre që i plotësojnë premtimet, kur
                      marrin përsipër diçka; e atyre që durojnë në kohë
                      skamjeje, sëmundjeje dhe lufte. Këta janë besimtarët e
                      vërtetë dhe këta janë ata që e kanë frikë Allahun.” 2:177.
                    </span>{' '}
                    Gjithashtu, Zoti thotë:{' '}
                    <span className="highlight">
                      “Allahu ju urdhëron që amanetet t’jua ktheni atyre që u
                      përkasin dhe, kur të gjykoni midis njerëzve, të gjykoni
                      drejt. Vërtet, Allahu ju këshillon mrekullueshëm dhe
                      vërtet, Allahu dëgjon çdo gjë dhe vështron të gjitha
                      punët!” 4:58,
                    </span>{' '}
                    <span className="highlight">
                      “Në të vërtetë, Allahu urdhëron drejtësinë, mirësinë dhe
                      ndihmën për të afërmit, si dhe ndalon imoralitetin, veprat
                      e shëmtuara dhe dhunën. Ai ju këshillon, në mënyrë që ju
                      t’ia vini veshin.” 16:90,
                    </span>{' '}
                    Shtyllat e besimit dhe pesë shtyllat e Islamit (nënshtrimit
                    ndaj Zotit)
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Përveç besimit në një Zot, besimit në të gjithë profetët dhe
                    të dërguarit dhe në librat (shpalljet) e zbritura,
                    muslimanët gjithashtu e besojnë ekzistencën e engjëjve
                    (melaikeve; p.sh. krye-engjëlli Xhibril [Gabriel] është
                    përgjegjës për përcjelljen e shpalljes) dhe ditën e fundit,
                    Ditën e Llogarisë (Ditën e Gjykimit), kur çdo individ do të
                    mbahet në përgjegjësi për veprat e bëra. Veprat do të
                    gjykohen nga Allahu dhe për shkak të tyre njeriu do të futet
                    në parajsë ose në ferr. Allahu është i drejtë, shpërblen dhe
                    dënon në mënyrë të drejtë, megjithatë, një nga cilësitë e
                    Tij është se Ai është më i mëshirshmi i mëshiruesve.
                    Muslimanët gjithashtu besojnë në vendimet e Allahut apo
                    paracaktimin e Tij; që, të gjitha të mirat dhe të këqijat
                    janë përpjesëtuar dhe se Allahu ka dituri të plotë për çdo
                    gjë. Megjithatë, çdo njeri ka vullnet të lirë brenda fushës
                    së përgjegjësisë dhe nuk është asgjë e paracaktuar kundër
                    vullnetit të tij, prandaj ka mundësi të bëj zgjedhje në
                    jetë.Përveç gjashtë parimeve të mësipërme të besimit, feja
                    Islame i ka pesë detyra themelore që një musliman
                    vullnetarisht i praktikon, të cilat janë të njohura si pesë
                    shtyllat e Islamit.Allahu thotë në Kuran:
                  </p>
                  <p className="description pt">
                    <span className="highlight">
                      “Ky është Libri (Kurani) në të cilin nuk ka dyshim. Ai
                      është udhërrëfyes për të devotshmit,të cilët besojnë në të
                      fshehtën, falin namazin dhe japin nga ajo që u kemi dhënë
                      Ne; dhe për ata që besojnë në atë që të është shpallur ty
                      (o Muhamed), në atë që është shpallur përpara teje dhe që
                      me bindje i besojnë jetës tjetër. Ata janë udhëzuar në
                      rrugë të drejtë nga Zoti i tyre dhe pikërisht ata janë të
                      shpëtuarit.” Kuran, 2:2-5.
                    </span>{' '}
                    Muslimanët praktikues, i ndjekin urdhrat e Allahut me qëllim
                    të kërkimit të kënaqësisë së Tij. Por, përveç kësaj,
                    urdhërimet vetvetiu përmbajnë urtësi të pandara. Pesë
                    shtyllat e Islamit formojnë kështu bazën e jetës së
                    muslimanit/es.Shtylla e parë siç u përmend më parë, është të
                    dëshmosh se askush nuk meriton të adhurohet përveç Allahut,
                    dhe se profeti Muhamed, paqja dhe lëvdatat qofshin mbi të,
                    është i dërguari i fundit. Kjo në fakt është thelbi i
                    Islamit.Shtylla e dytë është që të kryesh pesë kohët e
                    namazit.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={3}
              marginTop={'4%'}
              justifyContent="flex-end"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Allahu thotë:
                    <span className="highlight">
                      “Me të vërtetë, Unë jam Allahu, s’ka zot tjetër përveç
                      Meje, prandaj, vetëm Mua më adhuro dhe kryej faljen për të
                      më kujtuar Mua!” 20:14
                    </span>
                    . Dhe në një ajet tjetër:{' '}
                    <span className="highlight">
                      “Lexo (o Muhamed) atë që të është zbritur ty prej Librit
                      (Kuranit) dhe fale namazin! Vërtet që namazi të ruan nga
                      shthurja dhe nga çdo vepër e shëmtuar dhe vërtet që
                      përmendja e Allahut është më e madhe! Allahu di gjithçka
                      që ju bëni.” 29:45
                    </span>
                    . Namazi është koha për të dalë para Allahut dhe për ta
                    shfaqur besimin (me vepër), për ta falënderuar Atë për të
                    mirat që na ka dhuruar, si dhe për t’i kërkuar Atij udhëzim
                    dhe falje. Nëpërmjet përkuljes dhe përuljes në sexhde,
                    muslimanët shprehin përuljen maksimale para të
                    Plotfuqishmit.Nëse shikojmë në konceptin e PNL (Programimi
                    Neuro-Linguistik), do të shohim se ka dy drejtime në
                    marrëdhënie midis procesit të mendimit (Neuro), komunikimit
                    (Linguistik) dhe sjelljeve tona (Programimi). Nëse e
                    ndryshon njërën, shkaktohen ndryshime në dy variablat tjera.
                    Pra, nëse ndryshon sjelljen tënde për të kryer namazin (i
                    cili është veprim fizik si dhe komunikim verbal me Allahun),
                    ai natyrisht do të ndikojë në procesin e mendimit tënd -
                    duke e rritur besimin tënd si dhe duke të inspiruar për t’iu
                    afruar Allahut me punë të mira.
                  </p>
                  <p className="description pt">
                    Shtylla e tretë është të agjërosh muajin e Ramazanit (muajin
                    e nëntë të kalendarit Islam); çdo ditë, nga agimi deri në
                    perëndim të Diellit. Allahu thotë:{' '}
                    <span className="highlight">
                      “O besimtarë! Ju është urdhëruar agjërimi, ashtu si u
                      ishte urdhëruar atyre para jush, që të mund të ruheni nga
                      të këqijat.” 2:183
                    </span>{' '}
                    Agjërimi i mundëson njeriut zhvillimin e vetëkontrollit dhe
                    e çliron atë për ta përkushtuar trupin dhe shpirtin në
                    adhurim. Ramazani gjithashtu është një mundësi e madhe për
                    ne, për t’i vlerësuar/çmuar të mirat që Allahu na i ka dhënë
                    dhe për të shijuar atë që të uriturit dhe varfrit e
                    përjetojnë gjithandej globit; që është gjë e shpeshte dhe e
                    zakonshme.Shtylla e katërt është zekati apo dhënia e 2.5% të
                    pasurisë tepricë atyre që ka urdhëruar Allahu t’u jepet. Një
                    prej parimeve më të rëndësishme të Islamit është se çdo gjë
                    i përket Zotit dhe se pasuria i është besuar qenies
                    njerëzore. Fjala “zekat” nënkupton të dyja: pastrim dhe
                    rritje.
                  </p>
                  <p className="description pt">
                    Pronësitë tona pastrohen duke e dhënë një pjesë të saj për
                    ata që kanë nevojë, dhe, njëjtë si krasitja/shkurtimi i
                    bimëve, ky “shkurtim” i pasurisë balancon dhe nxit
                    rritje/shtim të ri. Allahu thotë:{' '}
                    <span className="highlight">
                      “Lëmosha e zekatit është vetëm për të varfrit, për
                      nevojtarët, për ata që janë ngarkuar me mbledhjen dhe
                      administrimin e tij,për lirimin e skllevërve, për të
                      rënduarit nga borxhet, për (luftëtarët në) rrugën e
                      Allahut dhe për udhëtarët e mbetur rrugëve. Këto janë
                      detyrat e vëna prej Allahut. Vërtet, Allahu është i
                      Gjithëdijshëm dhe i Urtë.” 9:60
                    </span>
                    . Dhënia e vazhdueshme e zekatit tregon se dashuria për
                    Allahun në zbatimin e urdhrave të Tij është më e madhe se sa
                    dashuria për pasurinë.
                  </p>

                  <p className="description pt">
                    Zekati gjithashtu shërben për t’u ndihmuar të varfërve,
                    atyre që kanë borxhe, e mbron shtetin si dhe e forcon
                    besimin.Shtylla e pestë me të cilën detyrohet një
                    musliman/e, është që ai/ajo të kryejë haxhin (pelegrinazhin)
                    së paku një herë në jetën e tij, nëse ka mundësi
                    materiale.Allahu thotë:{' '}
                    <span className="highlight">
                      “Me të vërtetë, Faltorja e parë e ngritur për njerëzit,
                      është ajo në Bekkë (Mekë), e bekuar dhe udhërrëfyese për
                      popujt. Në të ka shenja të qarta (që janë): vendi i
                      Ibrahimit (mekami Ibrahim); (fakti që) kush hyn në të
                      është i sigurt; dhe vizita (për haxh) e kësaj Faltoreje (e
                      cila është detyrë) për këdo që ka mundësi ta përballojë
                      rrugën. E kush nuk beson, Allahu nuk ka nevojë për
                      askënd.” 3:96-97
                    </span>
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Mesatarisht, rreth 2 milionë njerëz marrin pjesë në haxh për
                    çdo vit, duke paraqitur një pamje mahnitëse për të gjithë
                    njerëzimin. Haxhi paraqet unitetin, pavarësisht
                    shumëllojshmërisë, ngulitë një ndjenjë humaniteti e, për
                    haxhiun frymëzon durim dhe tolerancë. Udhëtimi në vetvete,
                    është tërësisht shpirtëror, i pakrahasueshëm me ndonjë
                    udhëtim tjetër në botë.Edhe pse fjalori e përkufizon Islamin
                    si “besimi fetar i muslimanëve”, Islami në fakt, është shumë
                    më shumë se kaq. Islami është mënyrë e jetës; sistem i tërë
                    jetese që përshkon çdo aspekt jetës së përditshme të një
                    individi.
                  </p>
                  <p className="description pt">
                    Praktikimi i Islamit, është vërtet përmbushës i nevojave të
                    njeriut dhe mundëson që njeriu të jetë në paqe me vetveten,
                    me Krijuesin e tij, me familjen dhe fqinjët e tij, si dhe me
                    shoqërinë në tërësi. Gjithësia është e mbushur – si një
                    libër i mrekullueshëm me vizatime – me “shenja”, të cilat
                    dëshmojnë për Krijuesin e saj dhe na përkujtojnë neve – nëse
                    kemi zemra të pastra dhe sy që shohin – për fuqinë e Zotit,
                    madhështinë dhe bukurinë e Tij.Allahu thotë në Kuran:“Me të
                    vërtetë, në qiej dhe në Tokë ka shenja për ata që besojnë.
                    Edhe në krijimin tuaj dhe të kafshëve të shpërndara nëpër
                    Tokë, ka shenja për besimtarët e bindur.
                  </p>
                  <p className="description pt">
                    <span className="highlight">
                      “Edhe në ndërrimin e natës dhe të ditës, edhe në ujin që
                      Allahu e zbret nga qielli, duke gjallëruar nëpërmjet tij
                      tokën e shkretuar, edhe në ndërrimin e erërave, ka shenja
                      për njerëzit që mendojnë.” 45:3-5
                    </span>
                    . Në përfundim, Zoti sipas Islamit është i
                    Jashtëzakonshëm/Mrekullueshëm, i Gjithëfuqishëm, Krijues i
                    Gjithëdijshëm dhe ligjvënës, ndonëse në të njëjtën kohë
                    është i Mëshirshëm, Bujar dhe Falës. Njeriu, si krijesë dhe
                    shërbëtor i Tij, qëndron para Tij pa ndërmjetës, duke u
                    takuar me Të përmes namazit në këtë jetë të shkurtër dhe
                    takim drejtpërdrejt më Të pasi kjo jetë të përfundojë. Sipas
                    Islamit, Zoti nuk vepron gjëra të njëjta sikur ato të
                    krijesave dhe Ai nuk e bën Veten të qasshëm nëpërmjet
                    idhujve apo fotografive. Ai është si është, absolut dhe i
                    Përjetshëm, dhe të këtillë e adhurojnë muslimanët.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </section>
    </div>
  );
};

export default Belief;
