import React from 'react';
import Belief from '../pages/Belief';
import GameOver from '../pages/GameOver';
import Home from '../pages/Home';
import InnerJoy from '../pages/InnerJoy';
import IsLifeAGame from '../pages/IsLifeAGame';
import NotFound from '../pages/NotFound';
import Reason from '../pages/ReasonFromIslam';
import WhyIslam from '../pages/WhyIslam';

export const PublicRoutes = [
  {
    path: '/',
    name: 'Home',
    element: <Home />,
  },
  {
    path: '/a-eshte-jeta-thjesht-loje',
    name: 'A eshte jeta thjesht loje',
    element: <IsLifeAGame />,
  },
  {
    path: '/rehatia-e-mbrendshme',
    name: 'Rehatia E Mbrendshme',
    element: <InnerJoy />,
  },
  {
    path: '/gameover',
    name: 'Game Over',
    element: <GameOver />,
  },
  {
    path: '/perse-islami',
    name: 'Perse Islami',
    element: <WhyIslam />,
  },
  {
    path: '/qellimi-sipas-islamit',
    name: 'Qellimi sipas Islamit',
    element: <Reason />,
  },
  {
    path: '/cfare-muslimanet-besojne',
    name: 'Cfare muslimanet besojne',
    element: <Belief />,
  },
  {
    path: '*',
    name: 'Not Found',
    element: <NotFound />,
  },
];
