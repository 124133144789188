import React from 'react';
import Banner from '../components/Banner/Banner';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Heading from '../components/Heading/Heading';
import Box from '@mui/material/Box';
import Card from '../components/Card/Card';
import { useTranslation } from 'react-i18next';

import './IsLifeAGame.scss';
import { Link } from 'react-router-dom';

const IsLifeAGame = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="banner">
        <Banner />
        <div className="banner-header">
          <img src={require('../assets/images/title1.png')} />
        </div>
      </div>
      <section>
        <Box>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={3}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Me siguri se, këtë shkrim, në këtë moment, je duke e lexuar
                    në dhomën tënde, i ulur në karrige dhe sigurisht se ke edhe
                    rroba të veshura. Unë të pyes: Për çfarë arsye? Pse i ke
                    rrobat e veshura dhe çfarë roli luan karriga? Pasi që këto
                    janë pyetje retorike, s’ke nevojë të përgjigjesh, sepse të
                    gjithë e dimë përgjigjen.
                  </p>
                  <p className="description pt">
                    Qëllimi i karriges është që të na mundësojë uljen në të duke
                    e mbështetur peshën tonë, ndërsa rrobat kanë për detyrë të
                    na ngrohin trupin, të na e mbulojnë lakuriqësinë dhe të na
                    bëjnë të dukemi mirë. Tani nga dhoma jote më lejo të të
                    mbartë në një pyll kudo diku në botë; natyrisht që në atë
                    pyll duhet të ketë drunj dhe në njërin nga ato drunj është
                    një molë (lloj fluture e familjes “lepidoptera”; në këtë
                    rast bëhet fjalë për larvën e saj).
                  </p>
                  <p className="description pt">
                    Kjo molë është duke e thithur limfën e drurit, ndërsa nën të
                    gjendet një molë tjetër, roli i së cilës është tejet i
                    çuditshëm: ajo i thith jashtëqitjet e molës së parë. Kjo për
                    shkak se mola e parë, gjatë thithjes së limfës, aty për aty
                    i nxjerr mbeturinat e saj. Me siguri je duke menduar “ku do
                    të dalë me këtë?” Mirë, së pari le ta sqarojmë se cili është
                    qëllimi i molës së dytë. Qëllimi i saj është pastrimi i
                    jashtëqitjes së molës së parë, në mënyrë që të mos rrjedh
                    përgjatë drurit, e që milingonat dhe insektet tjera të mos i
                    vinin pas gjurmës dhe ta hanin molën e parë. Më fjalë të
                    tjera, mola e dytë është polici e sigurimit për molën e
                    parë.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid container marginTop={'4%'} justifyContent="flex-end">
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Tani që e kuptove këtë, me siguri nuk ke ditur asgjë rreth
                    kësaj mole tre minuta më parë, në fakt, edhe sikur të
                    ndodhte gjenocid mbi molet, nuk do të të interesonte fare...
                    mirë, shumicës prej jush. Sido që të jetë, ne i atribuojmë
                    qëllim një krijese të tillë kaq të parëndësishme, dhe, t’i
                    kthehemi rrobave dhe karriges sonë, të cilat janë objekte të
                    pajeta, pa ndjenja dhe pa intelekt, u atribuojmë qëllime
                    edhe atyre gjithashtu, kurse nuk i atribuojmë qëllim
                    ekzistencës sonë. A nuk është kjo absurde?!
                  </p>
                  <p className="description pt">
                    Të besosh se nuk ke kurrfarë qëllimi në jetë, nuk është
                    vetëm irracionale por edhe problematike, pasi që kjo do të
                    thotë se shumë nga ato gjëra që kemi arritur si racë
                    njerëzore, nuk kishin për të ndodhur fare, meqë shumë nga
                    njerëzit që kanë arritur gjëra të mahnitshme, duke e
                    përfshirë edhe zbulimin e penicilinës, nuk do t’i shtynte
                    asgjë për t’i arritur ato çfarë kanë arritur. Kjo sepse,
                    pikërisht këta individë, i janë qasur jetës nga një qëllim i
                    cili i ka nxitur, pa të cilin do të ishim sikur kafshët,
                    duke iu nënshtruar instinkteve tona, apo robotë kimikë që
                    shëtisin poshtë-lart, duke pritur që acidi i baterisë të
                    shterohet! E, pse na qenka irracionale?
                  </p>
                  <p className="description pt">
                    Është irracionale, sepse nëse çdo gjë që zbulojmë në natyrë
                    e ka një qëllim të caktuar, duke e përfshirë edhe molën e
                    parëndësishme, si dhe gjërat të cilat ne i zhvillojmë apo
                    krijojmë, atëherë logjikisht rrjedh se edhe ne duhet të kemi
                    një qëllim gjithashtu. Ta mohosh këtë, është ngjashëm sikur
                    të besosh në gjëra pa pasur asnjë argument, sepse nuk ka
                    ndonjë argument që të pohojmë se ne nuk kemi ndonjë qëllim
                    në jetë. Përkundrazi, kemi argumente për të thënë se gjërat
                    kanë qëllime të caktuara dhe këtë mund ta arrijmë si
                    përfundim edhe për veten tonë gjithashtu.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <h1 className="card-heading np">
                    Sikur që Kurani me elokuencë shpjegon:
                  </h1>
                  <p className="description pt">
                    <span className="highlight">
                      “Me të vërtetë, në krijimin e qiejve dhe të Tokës dhe në
                      ndërrimin e natës e të ditës, ka shenja për mendarët, për
                      ata që e përmendin Allahun duke qëndruar në këmbë, ndenjur
                      ose shtrirë dhe që meditojnë për krijimin e qiejve dhe të
                      Tokës (duke thënë:) “O Zoti ynë! Ti nuk i ke krijuar kot
                      këto - lartësuar qofsh (nga çdo e metë)! Prandaj na ruaj
                      nga ndëshkimi i zjarrit.” Kuran, 3:190-191
                    </span>
                  </p>
                  <p className="description pt">
                    Në përfundim, mund të themi se pa një qëllim të caktuar, nuk
                    do të kishim në fakt një kuptim më të thellë të ekzistencës
                    sonë. Për shembull, nëse e marrim një pikëpamje shkencore
                    vërtet jonjerëzore, në lidhje me ekzistencën tonë, ajo thotë
                    se “ne gjendemi mbi një anije që është duke u fundosur”. Kjo
                    anije quhet gjithësi, sepse sipas shkencëtarëve, gjithësia
                    do ta vuajë një vdekje nga nxehtësia dhe një ditë Dielli do
                    ta shkatërrojë Tokën, prandaj edhe kjo anije një ditë do të
                    fundoset. Kështu që unë të pyes: Cili është qëllimi i
                    bamirësisë së rigrupimit dhe renditjes së karrigeve në
                    kuvertë, apo bamirësia ndaj asaj gruas së moshuar, duke i
                    dhënë një gotë qumështi? Ne të gjithë do të vdesim një ditë.
                    Qëllimi i jep kuptim më të thellë jetës sonë. Kështu që,
                    tani ne mund të pyesim: “Cili është qëllimi ynë në jetë?”
                  </p>
                  <h1 className="card-heading">
                    Koncepti Islam mbi qëllimin e jetës bazohet në dy formulime
                    themelore:
                  </h1>
                  <ul>
                    <li>
                      <p className="description">
                        Është racionale të besosh në Zot
                      </p>
                    </li>
                    <li>
                      <p className="description">
                        Ka arsye të shëndosha për të besuar se Kurani është
                        fjala e Zotit
                      </p>
                    </li>
                  </ul>
                  <p className="description">
                    Seksioni tjetër, 
                    <span className="highlight"> <Link to="/perse-islami"> “Islami i shpalosur”</Link></span>, shpjegon arsyen pse
                    Islami është i vërtetë dhe shpjegon qëllimin e ekzistencës
                    sonë.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </section>
    </div>
  );
};

export default IsLifeAGame;
