export default {
  header: {
    reasonOfLife: 'Cili është qëllimi i jetës?',
    unfoldedIslam: 'Islami i shpalosur',
    dropdown: {
      isLifeJustAGame: 'A është jeta thjeshtë një lojë?',
      innerPeace: 'Rehatia e mbrendshme',
      gameOver: 'Game over',
      whyIslam: 'Përse Islami?',
      reason: 'Qëllimi sipas islamit?',
      belief: 'Cfarë muslimanet besojnë?',
    },
  },
  button: {
    readMore: 'Lexo më shumë',
  },
  cards: {
    card1: {
      title: 'A është jeta thjesht një lojë?',
      description:
        'Me siguri se, këtë shkrim, në këtë moment, je duke e lexuar në dhomën tënde, i ulur në karrige dhe sigurisht se ke edhe rroba të veshura. Unë të pyes: Për çfarë arsye? Pse i ke rrobat e veshura dhe çfarë roli luan karriga? Pasi që këto janë pyetje retorike, s’ke nevojë të përgjigjesh, sepse të gjithë e dimë përgjigjen.',
    },
    card2: {
      title: 'Rehatia e mbrendshme',
      description:
        'A mund të ndjejmë ndonjëherë rehati? A ekziston në të vërtetë rehatia? Që të ndjejmë rehati të vërtetë shpirtërore dhe mendore, duhet ta kërkojmë atë. A nuk është e vështirë që në disa situata të ndihesh rehat? Në fund të fundit, ka disa gjëra që na prishin rehatinë pothuajse të gjithë neve. ',
    },
    card3: {
      title: 'Game Over',
      description:
        'Për një njeri të rëndomtë, vdekja në asnjë mënyrë nuk është një çështje e këndshme apo temë e diskutimit. Kjo temë, është dëshpëruese dhe e zymtë – vrasëse e vërtetë e kënaqësive; temë që do t’i përshtatej vetëm një shtëpie funeralesh. Njeriu i rëndomtë, ashtu i zhytur në vetën e tij.',
    },
    card4: {
      title: 'Përse Islami ?',
      description:
        'Para se ta shpalosim përgjigjen Islame rreth qëllimit e jetës, ky artikull shpjegon arsyet se pse Islami është e vërteta. Që nga agimi i njerëzimit, ne kemi kërkuar për ta kuptuar natyrën dhe rolin tonë në të. Në këtë kërkim të qëllimit të jetës, shumë njerëz i janë kthyer fesë.',
    },
    card5: {
      title: 'Qëllimi sipas islamit?',
      description:
        'Pyetja më e rëndësishme në jetë është “Përse jemi këtu?”.E pra, përse jemi këtu? Për të grumbulluar famë dhe pasuri? Për të bërë muzikë dhe fëmijë? Apo për të qenë njeriu më i pasur në varreza, siç thuhet me shaka: “Ai i cili vdes me më se shumti lodra, fiton!”.',
    },
    card6: {
      title: 'Cfarë muslimanet besojnë?',
      description:
        'Mesazhi themelor i Islamit është i thjeshtë: Duhet adhuruar vetëm Zoti.Kurani (shkrimi i shenjtë i shpallur i Islamit) thotë:“Thuaj(O Muhamed): ‘Ai është Allahu, Një dhe i Vetëm! Allahu është Absoluti, të Cilit i përgjërohet gjithçka në amshim. Ai as nuk lind, as nuk është i lindur. Dhe askush nuk është i barabartë (a i krahasueshëm) me Atë!’” ',
    },
  },
  switch: {
    primary: 'Cili është qëllimi i jetës?',
    secondary: 'Islami i shpalosur',
  },
  isLifeAGame: {
    text1:
      'Me siguri se, këtë shkrim, në këtë moment, je duke e lexuar në dhomën tënde, i ulur në karrige dhe sigurisht se ke edhe rroba të veshura. Unë të pyes: Për çfarë arsye? Pse i ke rrobat e veshura dhe çfarë roli luan karriga? Pasi që këto janë pyetje retorike, s’ke nevojë të përgjigjesh, sepse të gjithë e dimë përgjigjen. Qëllimi i karriges është që të na mundësojë uljen në të duke e mbështetur peshën tonë, ndërsa rrobat kanë për detyrë të na ngrohin trupin, të na e mbulojnë lakuriqësinë dhe të na bëjnë të dukemi mirë. Tani nga dhoma jote më lejo të të mbartë në një pyll kudo diku në botë; natyrisht që në atë pyll duhet të ketë drunj dhe në njërin nga ato drunj është një molë (lloj fluture e familjes “lepidoptera”; në këtë rast bëhet fjalë për larvën e saj). Kjo molë është duke e thithur limfën e drurit, ndërsa nën të gjendet një molë tjetër, roli i së cilës është tejet i çuditshëm: ajo i thith jashtëqitjet e molës së parë. Kjo për shkak se mola e parë, gjatë thithjes së limfës, aty për aty i nxjerr mbeturinat e saj. Me siguri je duke menduar “ku do të dalë me këtë?” Mirë, së pari le ta sqarojmë se cili është qëllimi i molës së dytë. Qëllimi i saj është pastrimi i jashtëqitjes së molës së parë, në mënyrë që të mos rrjedh përgjatë drurit, e që milingonat dhe insektet tjera të mos i vinin pas gjurmës dhe ta hanin molën e parë. Më fjalë të tjera, mola e dytë është polici e sigurimit për molën e parë.',
  },
};
