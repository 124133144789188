import React from 'react';
import Banner from '../components/Banner/Banner';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Heading from '../components/Heading/Heading';
import Box from '@mui/material/Box';
import Card from '../components/Card/Card';
import { useTranslation } from 'react-i18next';

import './IsLifeAGame.scss';
import { Link } from 'react-router-dom';

const WhyIslam = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="banner">
        <Banner />
        <div className="banner-header">
          <img src={require('../assets/images/perseislami.png')} />
        </div>
      </div>
      <section>
        <Box>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={3}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Para se ta shpalosim përgjigjen Islame rreth qëllimit e
                    jetës, ky artikull shpjegon arsyet se pse Islami është e
                    vërteta. Që nga agimi i njerëzimit, ne kemi kërkuar për ta
                    kuptuar natyrën dhe rolin tonë në të. Në këtë kërkim të
                    qëllimit të jetës, shumë njerëz i janë kthyer fesë. Shumica
                    e feve, bazohen në libra të pretenduara nga pasuesit e tyre
                    si të frymëzuara hyjnisht, pa asnjë fakt. Islami është
                    ndryshe, sepse bazohet në arsye dhe fakte. Ka shenja të
                    qarta, që dëshmojnë se libri i Islamit, Kurani, është fjala
                    e Zotit dhe ne kemi shumë arsye për ta mbështetur këtë
                    pohim:
                  </p>
                  <ul>
                    <li>
                      <p className="description">
                        Në Kuran gjinden shumë fakte shkencore dhe historike, të
                        cilat ishin të panjohura për njerëzit e asaj kohe dhe të
                        cilat janë zbuluar vetëm së fundi nga shkenca
                        bashkëkohore.
                      </p>
                    </li>
                    <li>
                      <p className="description">
                        Kurani është një stil unik i gjuhës, që nuk mund të
                        përsëritet. Kjo njihet si “Paimitueshmëria e Kuranit”.
                      </p>
                    </li>
                    <li>
                      <p className="description">
                        Ka parashikime të bëra në Kuran dhe nga Muhamedi,
                        lëvdatat dhe paqja qofshin mbi të, të cilat më vonë janë
                        bërë realitet.
                      </p>
                    </li>
                  </ul>
                  <p className="description">
                    Ky artikull paraqet dhe shpjegon faktet shkencore që gjenden
                    në Kuran, shekuj para se të “zbuloheshin” në shkencën
                    bashkëkohore. Është e rëndësishme të theksohet se Kurani nuk
                    është libër i shkencës, por është libër i shenjave
                    (argumenteve, mrekullive)”. Këto shenja janë për njerëzit,
                    që ata të njohin ekzistencën e Zotit dhe ta pranojnë
                    shpalljen e Tij. Siç e dimë, shkenca nganjëherë merr “kthesa
                    U”, ku ajo që dikur ka qenë shkencërisht e saktë, del e
                    pasaktë disa vjet më vonë. Në këtë artikull, vetëm fakte
                    shkencore të vërtetuara janë marrë parasysh, dhe jo teori e
                    hipoteza. Fakte shkencore në KuranKurani iu shpall Profetit
                    Muhamed, lëvdatat dhe paqja qofshin mbi të, në shekullin e
                    7-të.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid container marginTop={'4%'} justifyContent="flex-end">
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Shkenca në atë kohë ishte primitive; nuk ka pasur teleskopë,
                    mikroskopë apo as diçka për së afërmi me teknologjinë që
                    kemi sot. Asokohe, njerëzit besonin qielli mbahej nga
                    shtylla të mëdha nëpër qoshet e një toke të rrafshët. Në
                    këtë ambient u shpall Kurani, duke përmbajtur shumë fakte
                    shkencore në tema të ndryshme, nga astronomia deri në
                    biologji, nga gjeologjia deri në sociologji. Disa njerëz
                    mund të pretendojnë se Kurani ka pësuar ndryshime, duke iu
                    përshtatur zbulimeve të reja shkencore të vërtetuara, por
                    kjo s’mund të jetë e vërtetë, sepse është fakt i dokumentuar
                    historikisht se Kurani është ruajtur në gjuhën e vet
                    origjinale. Kurani është shkruar dhe është mësuar përmendësh
                    nga njerëzit gjatë jetës së Profetit Muhamed, lëvdatat dhe
                    paqja qofshin mbi të! Një nga kopjet e Kuranit, që është
                    shkruar pak vite pas vdekjes së Profetit Muhamed, lëvdatat
                    dhe paqja qofshin mbi të, është e ruajtur në një muze në
                    Uzbekistan. Kjo kopje është mbi 1400 vjet e vjetër dhe është
                    pikërisht e njëjtë me Kuranin arabisht që kemi sot.
                  </p>
                  <p className="description">
                    Në vijim janë paraqitur nëntë fakte shkencore që gjenden në
                    Kuran:
                  </p>
                  <h1 className="card-heading">1. ZANAFILLA E JETËS</h1>
                  <p className="description">
                    Uji është element thelbësor për të gjitha gjallesat. Të
                    gjithë e dimë se uji është vital për jetë, por Kurani e bën
                    një deklaratë shumë të pazakontë:{' '}
                    <span className="highlight">
                      “...dhe bëmë nga uji çdo gjë të gjallë! Akoma nuk
                      besojnë?!” 21:30.
                    </span>
                  </p>
                  <p className="description">
                    Në këtë ajet, uji është theksuar si origjina e gjithë jetës.
                    Të gjitha gjërat e gjalla përbëhen nga qelizat. Ne, vetëm së
                    fundi e dimë se qelizat përbëhen kryesisht nga uji. Për
                    shembull, 80% të citoplazmës (materiali bazë i qelizës) së
                    një qelizë standarde shtazore, përshkruhet si ujë në tekstet
                    e biologjisë. Fakti se qeniet e gjalla përbëhen kryesisht
                    nga uji, u zbulua vetëm pas shpikjes së mikroskopit. Në
                    shkretëtirat e Arabisë, gjëja e fundit që dikush do ta
                    kishte menduar është se e gjithë jeta ka ardhur nga uji.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <h1 className="card-heading np">2. HEKURI</h1>
                  <p className="description">
                    Hekuri nuk është gjë e natyrshme e tokës. Ai nuk është
                    formuar në tokë, por ka zbritur në tokë nga hapësira e
                    jashtme (kozmosi). Kjo mund të tingëllojë e çuditshme, por
                    është e vërtetë. Shkencëtarët kanë zbuluar se miliarda vjet
                    më parë, Toka ishte mbërthyer nga meteorë të vegjël. Këta
                    meteorë të vegjël mbartnin me vete hekur, i cili vinte nga
                    yjet e largëta të cilat kishin eksploduar. Kurani, rreth
                    origjinës së hekurit, thotë:
                  </p>
                  <p className="description">
                    <span className="highlight">
                      “Ne e zbritëm hekurin, në të cilin ka forcë të fuqishme
                      dhe dobi për njerëzit.” 57:25.
                    </span>
                  </p>
                  <p className="description">
                    Zoti i ka përdorur fjalët
                    <span className="highlight"> “e zbritëm”</span> për hekurin.
                    Nga ky ajet, bëhet e qartë se hekuri nuk është material
                    tokësor, por u zbrit në tokë për të mirën e njerëzimit.
                    Fakti se hekuri është zbritur në tokë nga hapësira e
                    jashtme, është diçka e cila nuk mund të ishte e njohur nga
                    shkenca primitive e shekullit të 7-të.
                  </p>
                  <h1 className="card-heading">
                    3. QIELLI (ATMOSFERA) SI MBUROJË
                  </h1>
                  <p className="description">
                    Qielli (atmosfera) luan një rol vendimtar në mbrojtjen e
                    tokës. Qielli e mbron tokën nga rrezet vdekjeprurëse të
                    diellit. Sikur të mos ekzistonte qielli, rrezatimi i diellit
                    do ta kishte zhdukur çdo gjë të gjallë mbi tokë. Ai
                    gjithashtu vepron si një batanije e mbështjellë përreth
                    tokës, për ta mbrojtur atë nga të ftohtit ngrirës të
                    hapësirës. Temperatura vetëm pak mbi qiell është afërsisht
                    -270oC. Sikur kjo temperaturë ta arrinte tokën, planeti do
                    të ngrinte në çast. Qielli gjithashtu e mbron jetën në tokë,
                    duke e ngrohur sipërfaqen përmes ruajtjes së nxehtësisë
                    (efekti “serrë”) dhe duke i reduktuar temperaturat ekstreme
                    mes ditës dhe natës. Këto janë disa nga funksionet e shumta
                    mbrojtëse të qiellit. Kurani kërkon nga ne që ta marrim
                    parasysh qiellin në ajetin vijues:
                  </p>
                  <p className="description">
                    <span className="highlight">
                      “Ne e bëmë qiellin mbulesë të mbrojtur, por përsëri ata
                      shmangen nga shenjat që gjenden aty.” 21:3
                    </span>
                  </p>
                  <p className="description">
                    Kurani i drejtohet qiellit si një prej shenjave të Zotit.
                    Karakteristikat mbrojtëse të qiellit janë zbuluar nga
                    kërkimet shkencore të bëra në shekullin e 20-të.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-end"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <h1 className="card-heading np">4. MALET</h1>
                  <p className="description">
                    Kurani tërheq vëmendjen tonë në një karakteristikë shumë të
                    rëndësishme të maleve:
                  </p>
                  <p className="description">
                    <span className="highlight">
                      “A nuk jua kemi bërë Tokën si shtrat e malet si kunja?!”
                      78:6-7.
                    </span>
                  </p>
                  <p className="description">
                    Kurani tregon se malet kanë rrënjë të thella duke e përdorur
                    fjalën “kunja” për ta përshkruar atë. Në fakt, malet kanë
                    rrënjë të thella dhe fjala “kunja”, është përshkrim i saktë
                    për ta. Libri i titulluar “Toka”, nga gjeofizikani Frank
                    Press, shpjegon se malet janë sikur kunjat dhe janë të
                    varrosura thellë nën sipërfaqen e tokës. Mali Everest, lartësia e të cilit është rreth 9Km mbi
                    sipërfaqen e tokës, ka rrënjë më të thella se 125Km. Fakti
                    se malet kanë rrënjë -si kunja- të thella, nuk ishte i
                    njohur deri pas zhvillimit të teorisë së pllakave tektonike
                    në fillim të shekullit të 20-të.
                  </p>
                  <h1 className="card-heading">5. ZGJERIMI I GJITHËSISË</h1>
                  <p className="description">
                    Në një kohë kur shkenca e astronomisë ishte akoma primitive,
                    zgjerimi i gjithësisë është përshkruar në Kuran:
                  </p>
                  <p className="description">
                    <span className="highlight">
                      “Ne e kemi ndërtuar qiellin me fuqinë Tonë dhe Ne e
                      zgjerojmë atë.” 51:47.
                    </span>
                  </p>
                  <p className="description">
                    Fakti që gjithësia zgjerohet vazhdimisht u zbulua në
                    shekullin e fundit. Fizikani Stefën Haking (Stephen
                    Hawking), në librin e tij “Një histori e shkurtër e kohës”,
                    shkruan: “Zbulimi se gjithësia zgjerohet vazhdimisht ishte
                    një nga revolucionet më të mëdha intelektuale të shekullit
                    të 20-të”. Kurani e ka përmendur zgjerimin e gjithësisë edhe
                    para së të shpikej teleskopi.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <h1 className="card-heading np">6. ORBITA E DIELLIT</h1>
                  <p className="description">
                    Në 1512 astronomi Nikolla Koperniku, i dha shtytje teorisë
                    së tij se “Dielli është i palëvizshëm në qendër të sistemit
                    diellor dhe se planetët rrotullohen rreth tij”. Besimi se
                    Dielli është i palëvizshëm ishte i përhapur në mesin e
                    astronomëve deri në shekullin e 20-të. Tani, është i
                    vërtetuar fakti shkencor se Dielli nuk është i palëvizshëm,
                    por lëviz në një orbitë rreth qendrës së galaktikës sonë,
                    “Rruga e Qumështit”.
                  </p>
                  <p className="description">
                    Kurani e përmend orbitën e Diellit:
                  </p>
                  <p className="description">
                    <span className="highlight">
                      “Allahu është Ai, i Cili krijoi natën dhe ditën, Diellin
                      dhe Hënën; secili prej tyre noton në orbitë.” 21:33
                    </span>
                  </p>
                  <p className="description">
                    Kurani do të kishte qenë gabim, sipas astronomëve të vetëm
                    disa dekadave më parë. Por, tani e dimë se vlerësimi i
                    Kuranit rreth lëvizjes së Diellit është në përputhje me
                    astronominë moderne.
                  </p>
                  <h1 className="card-heading">7. OQEANI</h1>
                  <p className="description">
                    Kurani përdor shëmbëlltyra për t’i përçuar kuptimet e tij të
                    thella. Këtu e përshkruan gjendjen e punëve të
                    jobesimtarëve:
                  </p>
                  <p className="description">
                    <span className="highlight">
                      “...si errësirat në detin e thellë, të cilat mbulohen nga
                      dallgët që ngrihen njëra mbi tjetrën, duke pasur sipër
                      tyre retë: shtresa errësire njëra mbi tjetrën, aq sa kur e
                      nxjerr dorën e vet, njeriu pothuajse nuk e sheh fare atë.
                      Ai, të cilit Allahu nuk i jep dritë, nuk do të gjejë më
                      dritë.” 24:40
                    </span>
                  </p>
                  <p className="description">
                    Është menduar shpesh se valët ndodhin vetëm në sipërfaqen e
                    oqeanit. Megjithatë, oqeanografët kanë zbuluar se ekzistojnë
                    valë të brendshme, që ndodhin nën sipërfaqen e oqeanit. Këto
                    valë janë të padukshme për syrin e njeriut dhe mund të vihen
                    re veçse nga pajisjet speciale. Kurani e përmend errësirën e
                    oqeaneve të thella, mbi të cilën ka valë, e mbi to valë të
                    tjera, pastaj retë sipër tyre. Ky përshkrim nuk është i
                    jashtëzakonshëm vetëm pse i përshkruan valët e brendshme në
                    oqean, por edhe se e përshkruan errësirën thellë në oqean.
                    Një qenie s’mund të zhytet më shumë se 70 metra pa pajisje
                    për frymëmarrje, drita është e pranishme në këtë thellësi,
                    por, nëse zhytemi 1000 metra, është plotësisht errësirë.
                    1400 vjet më parë nuk ka pasur nëndetëse apo pajisje
                    speciale për t’i vërejtur valët e brendshme ose errësirën
                    thellë në oqeane.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-end"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <h1 className="card-heading np">8. LËVIZJA DHE GËNJESHTRA</h1>
                  <p className="description">
                    Në kohën e Profetit Muhamed, lëvdatat dhe paqja qofshim mbi
                    të, jetonte një mizor shtypës, udhëheqës fisnor, i quajtur
                    Ebu Xhehël. Zoti shpalli një ajet të Kuranit për të
                    paralajmëruar atë:
                  </p>
                  <p className="description">
                    <span className="highlight">
                      “Jo, jo! Nëse ai nuk ndalon, Ne do ta kapim për ballukesh,
                      për ballukesh gënjeshtare dhe mëkatare.” 96:15-16
                    </span>
                  </p>
                  <p className="description">
                    Zoti, në këtë ajet nuk e quan gënjeshtar personin, por e
                    quan balluken e tij (pjesa e përparme e trurit)
                    <span className="highlight"> “gënjeshtare”</span> dhe
                    <span className="highlight"> “mëkatare”</span>, dhe e
                    paralajmëron atë për të ndaluar.
                  </p>
                  <p className="description">
                    Ky ajet është i rëndësishëm për dy arsye. E para është se,
                    pjesa e përparme e trurit tonë është përgjegjëse për lëvizje
                    vullnetare. Kjo është e njohur si lob ballore (ang. frontal
                    lobe). Një libër me titull “Themeloret e Anatomisë dhe
                    Fiziologjisë”, i cili përmban rezultatet e hulumtimit mbi
                    funksionet e kësaj pjese të trurit, thotë: “Motivimi dhe
                    parashikimi për të planifikuar dhe për të nxitur lëvizje,
                    ndodh në pjesën e përparme të lobeve ballore, zona
                    paraballore”. Nëse njeriu nuk ndalet së lëvizuri, thuhet se
                    pjesa e trurit e cila është përgjegjëse për lëvizje i ka
                    ngecur.
                  </p>
                  <p className="description">
                    Së dyti, studime të shumta kanë treguar se po kjo pjesë
                    (loba ballore), është përgjegjëse për funksionin e
                    gënjeshtrës në tru. Gjatë një studimi të tillë në
                    Universitetin e Pensilvanisë, në të cilin disa vullnetarë u
                    morën në pyetje të kompjuterizuara, u konstatua se kur
                    vullnetarët gënjenin, shkaktohej një rritje e ndjeshme e
                    aktivitetit në lëvozhgën paraballore dhe të brendshme (pjesa
                    e lobit ballor). Pjesa e përparme e trurit është përgjegjëse
                    për lëvizje dhe gënjeshtër. Kurani e lidh lëvizjen dhe
                    gënjeshtrën me këtë zonë. Këto funksione të lobit ballor,
                    janë zbuluar me pajisjen mjekësore të imazheve, e cila u
                    zhvillua në shekullin e 20-të.
                  </p>
                  <h1 className="card-heading">9. RECEPTORËT E DHIMBJES</h1>
                  <p className="description">
                    Për një kohë të gjatë, është menduar se të ndierit e
                    prekjeve dhe dhimbjeve, varej nga truri. Megjithatë, është
                    zbuluar se ka receptorë të dhimbjeve të pranishëm edhe në
                    lëkurë. Pa këto receptorë, njeriu nuk do të ishte në gjendje
                    të ndjente dhimbje.
                  </p>
                  <p className="description">
                    Shqyrtojeni ajetin e mëposhtëm, që flet për dhimbjen:
                  </p>
                  <p className="description">
                    <span className="highlight">
                      “Ata që s’besojnë në shenjat Tona, Ne padyshim, do t’i
                      djegim në zjarr. Sapo t’u digjet lëkura, Ne do t’ua
                      ndërrojmë atë me lëkurë tjetër, për të përjetuar dënimin.
                      Me të vërtetë, Allahu është i Plotfuqishëm dhe i Urtë.”
                      4:56
                    </span>
                  </p>
                  <p className="description">
                    Zoti u tregon njerëzve që nuk e besojnë mesazhin e tij, se,
                    kur ata të jenë në Zjarr dhe lëkurat e tyre të jenë djegur
                    (saqë të mos ndjejnë dhimbje), Ai do t'u japë atyre lëkura
                    të reja, në mënyrë që ata të vazhdojnë të ndjejnë dhimbjen.
                    Kurani e bën të qartë se të ndierit e dhimbjes varet nga
                    lëkura. Zbulimi i receptorëve të dhimbjes në lëkurë është
                    zbulim mjaft i vonshëm për biologjinë.
                  </p>
                  <h1 className="card-heading">Përfundim</h1>
                  <p className="description">
                    Këto janë vetëm disa nga shumë fakte shkencore që gjenden në
                    Kuran. Është e rëndësishme të theksohet se Kurani nuk është
                    libër i shkencës, por se, ai është në përputhje me shkencën.
                    Të pretendosh se faktet shkencore në Kuran gjenden aty
                    rastësisht, është irracionale. Shpjegimi më i mirë është se,
                    Zoti ia zbuloi këto njohuri Profetit Muhamed, lëvdatat dhe
                    paqja qofshin mbi të.
                  </p>
                  <p className="description">
                    Ashtu si Kurani përmban njohuri për botën natyrore, ai
                    gjithashtu përmban informacione rreth dimensioneve të
                    brendshme të shpirtrave tanë. Ai lidhet me ndjenjat,
                    dëshirat dhe nevojat tona. Kurani na informon se ne kemi një
                    qëllim në jetë dhe se, pasimi i udhëzimit të Zotit do të na
                    çojë drejt rehatisë shpirtërore në këtë jetë dhe në Parajsë
                    në jetën e përtejme, dhe se, refuzimi i mesazhit të Tij do
                    të çojë në depresion në këtë jetë dhe në Zjarr pas vdekjes.
                  </p>
                  <p className="description">
                    <span className="highlight">
                      “Ne do t’u tregojmë atyre shenjat Tona në hapësirat
                      tokësore e qiellore, si dhe në vetvete, derisa t’u bëhet
                      plotësisht e qartë se ai (Kurani) është e vërteta. Vallë,
                      a nuk të mjafton ty që, Zoti yt është Dëshmues i çdo
                      gjëje?!” 41:53
                    </span>
                  </p>
                  <p className="description">
                    Të lutem hape artikullin{' '}
                    <span className="highlight"> <Link to="/qellimi-sipas-islamit"> “Qëllimi sipas Islamit”</Link> </span>,
                    për të gjetur kuptimin e jetës sipas Islamit.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </section>
    </div>
  );
};

export default WhyIslam;
