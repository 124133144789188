import React from 'react';
import Banner from '../components/Banner/Banner';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Heading from '../components/Heading/Heading';
import Box from '@mui/material/Box';
import Card from '../components/Card/Card';
import { useTranslation } from 'react-i18next';

import './IsLifeAGame.scss';
import { Link } from 'react-router-dom';

const InnerJoy = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="banner">
        <Banner />
        <div className="banner-header">
          <img src={require('../assets/images/rehatia.png')} />
        </div>
      </div>
      <section>
        <Box>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={3}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <h1 className="card-heading np">LOJË STRESUESE!</h1>
                  <p className="description pt">
                    A mund të ndjejmë ndonjëherë rehati? A ekziston në të
                    vërtetë rehatia? Që të ndjejmë rehati të vërtetë shpirtërore
                    dhe mendore, duhet ta kërkojmë atë. A nuk është e vështirë
                    që në disa situata të ndihesh rehat? Në fund të fundit, ka
                    disa gjëra që na prishin rehatinë pothuajse të gjithë neve.
                  </p>
                  <p className="description pt">
                    Për shembull, a nuk është neveritëse kur ne jemi duke
                    pushuar në shtëpi dhe papritmas i plas alarmi një makine?
                    Ose kur e dëgjojmë goditjen e pikave të ujit? Si thua kur
                    një mizë gumëzhimë pranë veshit tonë? Apo kur jemi në një
                    fluturim të gjatë dhe dëgjojmë vazhdimisht të qara të
                    foshnjave?
                  </p>
                  <p className="description">
                    Në kushte të këtilla, shumë prej nesh do të mendonim në
                    vetvete: “Kam nevojë për pak paqe dhe qetësi!”
                  </p>
                  <p className="description">
                    Në fakt, sa më shumë që zgjat zhurma, aq më shumë vdesim për
                    qetësi.
                  </p>
                  <p className="description pt">
                    Të gjithë ne e kërkojmë rehatinë në gjëra të ndryshme; por
                    çfarë është ajo që na jep rehatinë e vërtetë shpirtërore?
                    Për t'iu përgjigjur kësaj: imagjino sikur je ulur në një
                    plazh të qetë; një fllad i lehtë butësisht fryn në flokun
                    tënd; rrezet e diellit ngrohtësisht po ta gudulisin lëkurën;
                    e dëgjon zërin e ujit që përplaset rreth teje; e sheh
                    diellin e portokalltë që perëndon... A do të ndiheshe i
                    qetësuar shpirtërisht? Shumica prej nesh do të thoshim: “Po,
                    në fakt, do të doja të isha atje që tani!” Por, çfarë nëse
                    një ditë më parë, je lajmëruar se ke tumor në tru? Ulur në
                    plazh, duke e ditur që ke vetëm edhe disa muaj për të
                    jetuar, a do të ndjeheshe rehat? Disi, kjo skenë qetësie e
                    paqeje nuk duket edhe aq e qetë e paqësore më! Rehatia e
                    jashtme është sikur një vegim. Nga larg duket se na jep
                    rehatinë që e kërkojmë, por kur e shikojmë nga afër, e
                    kuptojmë se nuk është e vërtetë.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid container marginTop={'4%'} justifyContent="flex-end">
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Relaksimi në plazh na u duk pamje mjaft e rehatshme, por
                    dija mbi pasjen e tumorit të trurit e shpartalloi iluzionin
                    tonë. Për ta shqyrtuar këtë më tej, parafytyroje shtëpinë
                    tënde të ëndrrave. Ndoshta kjo shtëpi luksoze ka dhjetë
                    dhoma gjumi? Plot makina të shpejta të parkuara në oborrin
                    tënd mbretëror me rrugë private?
                  </p>
                  <p className="description pt">
                    Ndoshta edhe shërbëtorë për t'u kujdesur për punët tua?
                    Përderisa e pikturojmë në imagjinatë shtëpinë tonë të
                    ëndrrave, mund të mendojmë, “Jeta do të ishte aq e qetë, e
                    rehatshme dhe paqësore në të!” Megjithatë, ka njerëz që
                    jetojnë në këso shtëpish, por tmerrohen nga tingujt e
                    alarmit të orës për çdo mëngjes, për shkak se janë mërzitur
                    nga angazhimet e vazhdueshme dhe nga vet jeta. Sa më afër
                    shtëpisë së ëndrrave të arrijmë, aq më shumë na qartësohet
                    se jemi duke parë një vegim (iluzion).
                  </p>
                  <p className="description pt">
                    Pse njohim njerëz që kanë gjithçka në dukje të jashtme, por
                    që mendojnë se për këtë jetë nuk ia vlen as të zgjohemi nga
                    gjumi? Nëse rehatia e jashtme nuk është e mjaftueshme,
                    atëherë çfarë është ajo që na jep rehatinë e vërtetë?
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Përgjigjja sipas islamit është e thjeshtë: ashtu siç kemi
                    nevoja fizike për gjëra të tilla si uji, gjithashtu kemi
                    edhe nevoja shpirtërore. Përmbushja e nevojave tona
                    shpirtërore, na jep rehati të brendshme.
                  </p>
                  <p className="description pt">
                    <span className="highlight">
                      “...ata që kthehen tek Allahu, ata që besojnë dhe zemrat e
                      të cilëve, kur përmendet Allahu, qetësohen. Vërtet, zemrat
                      qetësohen me përmendjen e Allahut!” Kuran, 13:27-28
                    </span>
                  </p>
                  <p className="description pt">
                    <span className="highlight">
                      “Cilindo që kryen vepra të mira, qoftë mashkull apo femër,
                      duke qenë besimtar, Ne do ta bëjmë që të kalojë jetë të
                      bukur dhe do ta shpërblejmë sipas veprave më të mira që ka
                      bërë.” Kuran, 16:97
                    </span>
                  </p>
                  <p className="description pt">
                  Besimi sipas islamit është i bazuar në dy parime: t’i besosh Zotit është e logjikshme dhe se, ka arsye të shëndosha për të besuar se Kurani është Fjala e Zotit.
                   Për të zbuluar më shumë, shihe seksionin
                   <span className="highlight"> <Link to={"/perse-islami"}>“Islami i shpalosur”</Link></span>.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </section>
    </div>
  );
};

export default InnerJoy;
