import React from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import './Card.scss';

const Card = (props) => {
  const {
    icon,
    title,
    title2,
    description,
    style = 'primary',
    description2,
    description3,
    description4,
    author,
    header,
    verse,
    controlled,
    href,
    children,
  } = props;

  const navigate = useNavigate();

  if (style === 'primary') {
    return (
      <div className="card">
        <div className="card-content">
          <div className="icon">{icon}</div>
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
      </div>
    );
  } else if (style === 'secondary') {
    return (
      <div className="card-secondary">
        <div className="card-content">
          <div className="icon">{icon}</div>
          <div className="title">{title}</div>
          <div className="description">{description}</div>
        </div>
        <p className="card-href" onClick={() => navigate(href)}>
          Lexo më shumë
        </p>
      </div>
    );
  } else if (style === 'third') {
    return (
      <div className="card-third">
        <div className="card-header">
          <img
            src={require('../../assets/images/star.png')}
            width={85}
            height={86}
          />
        </div>
        <div className="card-content">
          <div className="content-img">{icon ? icon : null}</div>
          <div className="sub-header style">{title2 ? title2 : null}</div>
          <div className="description style">
            {description ? description : null}
          </div>
          <div className="sub-header style">{header ? header : null}</div>
          <div className="description style">
            {verse ? verse : null} <br></br>
            {description2 ? description2 : null}
          </div>
          <div className="description style">
            {description3 ? description3 : null}
          </div>
          <div className="description style">
            {description4 ? description4 : null}
          </div>
          <div className="author style">{author ? author : null}</div>
          <div className="author style">{controlled ? controlled : null}</div>
        </div>
        <div className="card-footer">
          <img
            src={require('../../assets/images/star2.png')}
            width={85}
            height={86}
          />
        </div>
      </div>
    );
  } else if (style === 'fourth') {
    return (
      <div className="card-wrapper">
        <div className="card-fourth">
          <div className="card-content">
            <div className="icon">{icon}</div>
          </div>
        </div>
        <div className="description">{description}</div>
      </div>
    );
  } else if (style === 'fifth') {
    return (
      <div className="card-third">
        <div className="card-header">
          <img
            src={require('../../assets/images/star.png')}
            width={85}
            height={86}
          />
        </div>
        <div className="card-content">
          <div className="children">{children}</div>
        </div>
        <div className="card-footer">
          <img
            src={require('../../assets/images/star2.png')}
            width={85}
            height={86}
          />
        </div>
      </div>
    );
  }
};

export default Card;
