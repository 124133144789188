import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Banner from '../components/Banner/Banner';
import Button from '../components/Button/Button';
import Card from '../components/Card/Card';
import Heading from '../components/Heading/Heading';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Container, Tab, Tabs } from '@mui/material';
import svgImage from '../assets/images/ilustrimi.svg';
import svgLogo from '../assets/images/logov2.svg';
import svgStars from "../assets/images/stars.svg";

import './style.scss';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { t } = useTranslation();

  const [toggle, setToggle] = useState(1);

  const [toggleFirstCart, setToggleFirstCart] = useState(false);
  const [toggleSecondCart, setToggleSecondCart] = useState(false);
  const [toggleThirdCart, setToggleThirdCart] = useState(false);

  const handleCart = (type) => {
    if (type === 1) {
      setToggleFirstCart(!toggleFirstCart);
    } else if (type === 2) {
      setToggleSecondCart(!toggleSecondCart);
    } else {
      setToggleThirdCart(!toggleThirdCart);
    }
  };

  const toggleTab = (i) => {
    setToggle(i);
  };

  return (
    <div>
    <div className="banner2">
      <img src={svgStars} className="starsImage" />
      <img src={svgLogo} className="textImage" />
    </div>
      
      <section>
        <div className="heading-container">
          <Heading
            title={'Një njeri i zakonshëm gjatë jetës së tij shpenzon'}
          />
        </div>
        <Box>
          <Container maxWidth={'lg'}>
            <Grid container spacing={2}>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Card
                  icon={
                    <img
                      src={require('../assets/images/3.png')}
                      width={'100%'}
                      height={676}
                    />
                  }
                  title={'28 300'}
                  description={'orë shfletimi interneti'}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Card
                  icon={
                    <img
                      src={require('../assets/images/2.png')}
                      width={'100%'}
                      height={676}
                    />
                  }
                  title={'32 098'}
                  description={'orë ngrënie dhe pirje'}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Card
                  icon={
                    <img
                      src={require('../assets/images/1.png')}
                      width={'100%'}
                      height={676}
                    />
                  }
                  title={'90 360'}
                  description={'orë pune'}
                />
              </Grid>
              <Grid item lg={3} md={3} sm={12} xs={12}>
                <Card
                  icon={
                    <img
                      src={require('../assets/images/4.png')}
                      width={'100%'}
                      height={676}
                    />
                  }
                  title={'229 961'}
                  description={'orë gjumi'}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      </section>
      <section className="background">
        <div className="heading-container">
          <Heading title={'Ruaje kohën tënde'} />
        </div>
        <Box>
          <Container maxWidth={'lg'}>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
              <img
                className="section-image"
                src={svgImage}
                alt="Illustration"
              />
              </Grid>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <div className="view-middle-content-left">
                  <div className="view-middle-content-left-card1">
                    <div className="view-middle-content-left-card1-content">
                      <span className="view-moddle-content-left-card1-title">
                        Ç’TË MASHTROI TY QË NUK I BESOVE ZOTIT TËND BUJAR?{' '}
                        <span className="title">(Kurani, 82:6)</span>
                      </span>
                      <p className="view-moddle-content-left-card1-desc">
                        Të bëjme një shëti së bashku: Ti je punëdhënës dhe e ke
                        punësuar Albanin. Nëse Albani vjen tek ti në fund të
                        javës dhe, pavarësisht fillimit të vonshëm në punë,
                        largimit të hershëm nga puna, pushimit mjekësor,
                        pushimit për vdekjen e gjyshes, përsëri pushimit për
                        vdekjen e gjyshes dhe një liste me sjellje të pacipa; ai
                        prapseprap kërkoi pagën e tij të plotë. A do ta paguaje
                        atë siç kërkon?
                      </p>
                      <p
                        className={
                          toggleFirstCart
                            ? 'view-moddle-content-left-card1-desc active'
                            : 'view-moddle-content-left-card1-desc hidden'
                        }
                      >
                        Për shumicën prej nesh, përgjigja do të ishte një “Jo!”
                        e madhe. Me siguri që ne nuk do të ndiheshim rehat ta
                        paguanim Albanin, vetëm për t’i mundësuar atij qëndrim
                        në bashkësinë tonë të punës. E, si është pra puna me ne?
                        Ne mund të marrim frymë, të lexojmë, shkruajmë, punojmë,
                        udhëtojmë dhe gëzojmë shoqërimin me njerëz të mirë, e
                        pra, si është puna që ne e harrojmë Atë, i Cili na i dha
                        gjithë këto mirësi të shumta? Kjo është e habitëse për
                        faktin se, shumica prej nesh, edhe pse i harrojmë këto
                        mirësi, ose më me rëndësi, e harrojmë Atë që na i ka
                        dhënë ato, është përsëri Ai, të Cilit i drejtohemi kur
                        kemi më së shumti nevojë.
                      </p>
                      <p
                        className={
                          toggleFirstCart
                            ? 'view-moddle-content-left-card1-desc active'
                            : 'view-moddle-content-left-card1-desc hidden'
                        }
                      >
                        Kur një i dashur i yni sëmuret, ne i lutemi Atij, kur
                        një i dashur i yni vdes, ne përsëri i lutemi Atij,
                        pasiqë kjo është prirja jonë natyrore, sikurse Zoti
                        thotë:
                        <span className="highlight">
                          “Vërtet, zemrat qetësohen me përmendjen e Allahut!”
                          (Kurani, 13:28)
                        </span>
                      </p>
                      <p className="card-link" onClick={() => handleCart(1)}>
                        {!toggleFirstCart ? 'Lexo më shumë' : 'Redukto'}
                      </p>
                    </div>
                  </div>
                  <div className="view-middle-content-left-card2">
                    <div className="view-middle-content-left-card2-content">
                      <span className="view-moddle-content-left-card2-title">
                        MOS VALLË, KENI MENDUAR QË NE JU KEMI KRIJUAR KOT?!
                        <span className="title"> (Kurani, 23:115)</span>
                      </span>
                      <p className="view-moddle-content-left-card2-desc">
                        Pra, jeta është shumë qejf apo jo? E shtune mbrëma,
                        bukur. Dalje me gocat, bukur fare. Shoping derisa mos të
                        të mbajnë këmbët, edhe më bukur. Por, le t’ia bëjmë
                        vetes këto pyetje: “A mund të jetë jeta vetëm kaq? A
                        mund të jetë jeta thjesht për kënaqësi të përkohshme? A
                        nuk mund të ketë ndonjë qëllim më të lartë se sa thjesht
                        kalim i kohës në një vend, duke e shijuar atë dhe kjo të
                        jetë e tëra pastaj?”.
                      </p>
                      <p
                        className={
                          toggleSecondCart
                            ? 'view-moddle-content-left-card2-desc active'
                            : 'view-moddle-content-left-card2-desc hidden'
                        }
                      >
                        Gjithë këto tipare mahnitëse të natyrës, zbulime të
                        mrekullueshme, përparime në kapacitetin e njeriut etj,
                        por unë dhe ti, ne qenkemi këtu vetëm për një kohë të
                        shkurtër: për të ngrënë, për të fjetur, për të pjellur
                        dhe, ajo e frikshmja: për të vdekur?! Arsyeja pse kjo
                        duket e pazakontë - sikur të ishte e vërtetë, është se
                        atëherë vetë jeta do ishte anomalia më e madhe në fytyrë
                        të Tokës. Por, ju përgëzojmë me lajme të mira: “Nuk
                        është e tillë!”, ka një qëllim!
                      </p>
                      <p
                        className={
                          toggleSecondCart
                            ? 'view-moddle-content-left-card2-desc active'
                            : 'view-moddle-content-left-card2-desc hidden'
                        }
                      >
                        <span className="highlight">
                          Zoti, Ai që të krijoi ty, mua dhe të gjithë neve, e
                          bën të qartë këtë qëllim duke thënë: “Xhindet dhe
                          njerëzit i kam krijuar vetëm që të Më adhurojnë.”
                          (Kurani, 51:56)
                        </span>
                      </p>
                      <p
                        className={
                          toggleSecondCart
                            ? 'view-moddle-content-left-card2-desc active'
                            : 'view-moddle-content-left-card2-desc hidden'
                        }
                      >
                        Ky është një nocion i thjeshtë: “Vetë jeta është një
                        mekanizëm për shqyrtimin (ndarjen) e atyre që përpiqen
                        për punë të mira nga ata që përpiqen për punë të këqija;
                        ata që janë në ndjekje të së keqes, e ndjekin atë deri
                        në destinacionin përfundimtar të saj, ndërsa ata që janë
                        në ndjekje të së mirës, e ndjekin atë përgjatë gjithë
                        rrugës së tyre deri në parajsë.”
                      </p>
                      <p className="card-link" onClick={() => handleCart(2)}>
                        {!toggleSecondCart ? 'Lexo më shumë' : 'Redukto'}
                      </p>
                    </div>
                  </div>
                  <div className="view-middle-content-left-card3">
                    <div className="view-middle-content-left-card3-content">
                      <span className="view-moddle-content-left-card3-title">
                        PRA, CILAT DHUNTI TË ZOTIT TUAJ PO I MOHONI?!
                        <span className="title"> (Kurani, 55:16)</span>
                      </span>
                      <p className="view-moddle-content-left-card3-desc">
                        Vazhdojmë edhe pak: Ti ke gdhirë këtë mëngjes, shumë të
                        tjerë jo. Ke qenë në gjendje ta mbathësh vetveten, shumë
                        të tjerë nuk munden. Ke qenë në gjendje t’i shohësh
                        shumë prej postereve tona, e shumë të tjerë nuk shohin.
                        A nuk janë këto gjëra të mahnitshme që ne jemi në
                        gjendje t’i bëjmë, por prapseprap s’ua dimë vlerën e i
                        marrim për lojë?
                      </p>
                      <p
                        className={
                          toggleThirdCart
                            ? 'view-moddle-content-left-card3-desc active'
                            : 'view-moddle-content-left-card3-desc hidden'
                        }
                      >
                        Shiko jashtë dritares tënde! Seriozisht, çohu, ec deri
                        te dritarja dhe shih përsosmërinë e natyrës. A është e
                        mundur që e gjithë kjo të formohej rastësisht, apo mos
                        ndoshta ne jemi duke i mohuar argumentet e Krijuesit dhe
                        vetë Krijuesin? Krijuesi ynë, Allahu i Plotfuqishëm,
                        nxit të menduarit e këtyre gjërave në Librin e Tij,
                        Kuranin (shpallja e fundit për njerëzimin), në lidhje me
                        botën përreth nesh, sepse pa dyshim, argumenti për
                        ekzistimin e Krijuesit është vetë krijimi.
                      </p>
                      <p
                        className={
                          toggleThirdCart
                            ? 'view-moddle-content-left-card3-desc active'
                            : 'view-moddle-content-left-card3-desc hidden'
                        }
                      >
                        Mendo për gjënë e vetme e cila na lidh të gjithë neve:
                        vetë jetën. Ultratinguj, rreze x etj; Kurani, mbi 1400
                        vjet më parë, e ka shpjeguar në detaje procesin e
                        embriologjisë njerëzore. Ja, lexoje vetë:
                      </p>
                      <p
                        className={
                          toggleThirdCart
                            ? 'view-moddle-content-left-card3-desc active'
                            : 'view-moddle-content-left-card3-desc hidden'
                        }
                      >
                        <span className="highlight">
                          "Me të vërtetë, Ne e kemi krijuar njeriun prej palcës
                          së baltës, e pastaj, e bëmë atë pikë fare dhe e vumë
                          në një vend të sigurt (mitër). E mandej, pikën e farës
                          e bëmë droçkë gjaku, e pastaj e bëmë copëz mishi pa
                          formë; mandej, me atë mish bëmë eshtrat, pastaj i
                          veshëm eshtrat me mish dhe pastaj e bëmë atë (njeriun)
                          krijesë tjetër (duke i futur shpirtin). Qoftë
                          lartësuar Allahu, Krijuesi më i përsosur!" (Kurani,
                          23:12-14)
                        </span>
                      </p>
                      <p
                        className={
                          toggleThirdCart
                            ? 'view-moddle-content-left-card3-desc active'
                            : 'view-moddle-content-left-card3-desc hidden'
                        }
                      >
                        Kjo s’mund të jetë thjesht e qëlluar me fat të madh, apo
                        jo?
                      </p>
                      <p className="card-link" onClick={() => handleCart(3)}>
                        {!toggleThirdCart ? 'Lexo më shumë' : 'Redukto'}
                      </p>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </section>
      <section>
        <div className="switch-container">
          <div className="wrapper">
            <div className="tab-switch left text-center">
              <div
                // className="tab active"
                className={toggle == 1 ? 'tab active' : 'tab'}
                tab-direction="left"
                onClick={() => toggleTab(1)}
              >
                {t('switch.primary')}
              </div>
              <div
                className={toggle == 2 ? 'tab active' : 'tab'}
                tab-direction="right"
                onClick={() => toggleTab(2)}
              >
                {t('switch.secondary')}
              </div>
            </div>
          </div>
        </div>
        <Box>
          <Container maxWidth={'lg'}>
            <Grid container spacing={4}>
              {toggle == 1 ? (
                <>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card
                      icon={
                        <img src={require('../assets/images/belief.png')} />
                      }
                      title={t('cards.card1.title')}
                      description={t('cards.card1.description')}
                      style="secondary"
                      href="/a-eshte-jeta-thjesht-loje"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card
                      icon={
                        <img
                          src={require('../assets/images/innerpeace-icon.png')}
                        />
                      }
                      title={t('cards.card2.title')}
                      description={t('cards.card2.description')}
                      style="secondary"
                      href="/rehatia-e-mbrendshme"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card
                      icon={
                        <img
                          src={require('../assets/images/gameover-icon.png')}
                        />
                      }
                      title={t('cards.card3.title')}
                      description={t('cards.card3.description')}
                      style="secondary"
                      href="/gameover"
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card
                      icon={
                        <img
                          src={require('../assets/images/whyIslam-icon.png')}
                        />
                      }
                      title={t('cards.card4.title')}
                      description={t('cards.card4.description')}
                      style="secondary"
                      href="/perse-islami"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card
                      icon={<img src={require('../assets/images/icon.png')} />}
                      title={t('cards.card5.title')}
                      description={t('cards.card5.description')}
                      style="secondary"
                      href="/qellimi-sipas-islamit"
                    />
                  </Grid>
                  <Grid item lg={4} md={4} sm={12} xs={12}>
                    <Card
                      icon={
                        <img src={require('../assets/images/object.png')} />
                      }
                      title={t('cards.card6.title')}
                      description={t('cards.card6.description')}
                      style="secondary"
                      href="/cfare-muslimanet-besojne"
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </Box>
      </section>
    </div>
  );
};

export default Home;
