import React from 'react';
import Banner from '../components/Banner/Banner';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Heading from '../components/Heading/Heading';
import Box from '@mui/material/Box';
import Card from '../components/Card/Card';
import { useTranslation } from 'react-i18next';

import './IsLifeAGame.scss';
import { Link } from 'react-router-dom';

const Reason = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="banner">
        <Banner />
        <div className="banner-header">
          <img src={require('../assets/images/qellimi.png')} />
        </div>
      </div>
      <section>
        <Box>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={3}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    <span className="highlight">
                      Pyetja më e rëndësishme në jetë është: “Përse jemi këtu?”.
                    </span>{' '}
                    E pra, përse jemi këtu? Për të grumbulluar famë dhe pasuri?
                    Për të bërë muzikë dhe fëmijë? Apo për të qenë njeriu më i
                    pasur në varreza, siç thuhet me shaka: “Ai i cili vdes me më
                    se shumti lodra, fiton!”.
                  </p>
                  <p className="description">
                    Jo, duhet të ketë diçka tjetër në këtë jetë, kështu që le të
                    mendojmë rreth kësaj! Për të filluar, shiko përreth teje!
                    Edhe nëse jeton në ndonjë shpellë, ti je i rrethuar nga
                    gjëra të cilat ne njerëzit i kemi bërë me duart tona. Tani,
                    përse i kemi bërë ato gjëra? Përgjigjja, natyrisht, është se
                    i kemi bërë për të kryer disa funksione të veçanta për ne.
                  </p>
                  <p className="description pt">
                    Me pak fjalë: ne bëjmë gjëra për të na shërbyer. Pra, nëse
                    shikojmë nga ky kënd, atëherë përse na krijoi Zoti neve,
                    nëse jo për t’i shërbyer Atij?Nëse ne e pranojmë (besojmë)
                    Krijuesin tonë dhe besojmë se Ai krijoi njerëzimin për t’i
                    shërbyer Atij, pyetja e radhës është: “Si? Si mund t’i
                    shërbejmë Atij?” Pa dyshim se në këtë pyetje është
                    përgjigjur më së miri Ai i cili na ka krijuar. Nëse Ai na ka
                    krijuar për t’i shërbyer Atij, atëherë Ai pret që ne të
                    funksionojmë në një mënyrë të caktuar, në qoftë se duam ta
                    plotësojmë qëllimin tonë. Por, si mund ta dimë cila është
                    mënyra e duhur? Si mund ta dimë se çfarë pret Zoti prej
                    nesh?Mirë, merre parasysh këtë: Zoti neve na ka dhënë një
                    dritë, me të cilën mund ta gjejmë rrugën tonë. Edhe gjatë
                    natës, ne e kemi hënën që na ndriçon dhe yjet që na
                    drejtojnë.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid container marginTop={'4%'} justifyContent="flex-end">
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Zoti i ka dhënë kafshëve tjera sisteme udhëzuese, që u
                    përshtaten më së miri kushteve dhe nevojave të tyre. Zogjtë
                    që migrojnë mund të drejtohen, madje edhe në ditët e
                    mbuluara me re, nga polarizimi i dritës që kalon nëpër re.
                    Balenat lëvizin duke “lexuar” fushën magnetike të Tokës.
                    Salmonët kthehen me ndihmën e të nuhaturit prej oqeanit të
                    hapur, për të lindur në vendin e lindjes së tyre, në qoftë
                    se një gjë e tillë mund të imagjinohet. Peshqit i ndjejnë
                    lëvizjet e largëta me anë të receptorëve të presionit që
                    rreshtohen në trupin e tyre. Lakuriqtë dhe delfinët e verbër
                    të lumit, “shohin” me ndihmën e hidrolokatorit. Disa
                    organizma detare (ngjala elektrike si shembull i një
                    tensioni të lartë) prodhojnë dhe i “lexojnë” fushat
                    magnetike, duke ua bërë të mundshëm “shikimin” në ujërat e
                    turbullt, ose në errësirën e thellësive të oqeaneve.
                    Insektet komunikojnë me ndihmën e feromoneve. Bimët i
                    ndjejnë rrezet e Diellit dhe rriten në drejtim të tyre
                    (fototropizëm - prirje për t’u kthyer nga drita), kurse
                    rrënjët e tyre e ndjejnë gravitetin dhe rriten në drejtim të
                    Tokës (gjeotropizëm). Me pak fjalë: Zoti ka pajisur çdo
                    element të krijimit të Tij me udhëzim.
                  </p>
                  <p className="description pt">
                    A mund të besojmë se Ai nuk do të na jepte udhëzimin në
                    aspektin më të rëndësishëm të ekzistencës sonë, pra, në
                    raison d'etre – qëllimin e jetës tonë? Se Ai nuk do të na
                    jepte mjetet me të cilat do të arrinim të shpëtonim?E pra,
                    çfarë na tregon Krijuesi ynë, Zoti, rreth qëllimit të jetës
                    sonë? Zoti thotë në Kuran se Ai e krijoi njeriun që të jetë
                    mëkëmbës i Tij në Tokë, pra, ia ka besuar kujdesin mbi
                    Tokën. Themelorja që i është besuar njerëzve, pra,
                    përgjegjësia jonë, është që ta besojmë dhe ta adhurojmë
                    Zotin:“Xhindet dhe njerëzit i kam krijuar vetëm që të Më
                    adhurojnë.” Kuran, 51:56 Shumë e thjeshtë! Qëllimi i
                    krijimit të njeriut është adhurimi i Krijuesit. Kuptimi i
                    adhurimit në Islam mundëson që tërë jeta e njeriut të jetë
                    akt adhurimi, për sa kohë që objektivi i asaj jete është
                    kënaqësia e Zotit, e cila arrihet duke bërë mirë dhe duke u
                    ruajtur nga të këqijat. Një person mund t’i kthejë
                    aktivitetet e tij të përditshme në akte adhurimi, duke e
                    pastruar qëllimin e tij dhe duke kërkuar sinqerisht
                    kënaqësinë e Zotit përmes këtyre aktiviteteve.{' '}
                    <span className="highlight">
                      {' '}
                      Profeti Muhamed, lëvdatat qofshin mbi të, ka thënë:“Të
                      përshëndeturit e njerëzve është bamirësi; Të vepruarit me
                      drejtësi është bamirësi; T’i ndihmosh një udhëtari është
                      bamirësi; Fjala e mirë është bamirësi; Çdo hap i bërë
                      rrugës për në xhami është bamirësi; Heqja e një pengese
                      nga rruga është bamirësi.”
                    </span>
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Adhurimi i jep besimtarit shumë përfitime, të cilat
                    ndihmojnë për mirëqenien e tij shpirtërore dhe mirëqenien e
                    kësaj bote. Trupi i njeriut ka nevojë për burime materiale
                    për të ekzistuar, si për ushqim, pije dhe për nevojën e
                    riprodhimit. Sa për shpirtin, nevojat e tij nuk mund të
                    përmbushen përveçse përmes afërsisë me Zotin me anë të
                    besimit dhe bindjes ndaj Tij, të cilat mund të arrihen vetëm
                    përmes adhurimit.Zoti duhet të adhurohet në kohë të
                    vështira, por edhe në kohë të mirëqenies, dhe, vetëm duke e
                    kujtuar Atë, njeriu mund të gjejë rehatinë e brendshme
                    (shpirtërore):
                  </p>
                  <p className="description pt">
                    <span className="highlight">
                      “Ne e dimë mirë, që ty të ngushtohet zemra nga fjalët e
                      tyre, andaj, lartësoje Zotin tënd duke e lavdëruar dhe
                      bëhu prej atyre që i përulen Atij duke u falur! Dhe
                      adhuroje Zotin tënd derisa të të vijë vdekja!” Kuran,
                      15:97-99
                    </span>
                  </p>
                  <p className="description pt">
                    <span className="highlight">
                      “...ata që kthehen tek Allahu, ata që besojnë dhe zemrat e
                      të cilëve, kur përmendet Allahu, qetësohen. Vërtet, zemrat
                      qetësohen me përmendjen e Allahut!” Kuran, 13:27-28
                    </span>
                  </p>
                  <p className="description pt">
                    Më tej, Zoti thotë se Ai e bëri këtë jetë, në mënyrë që ta
                    sprovojë njeriun dhe se çdo person do të shpërblehet pas
                    vdekjes, sipas asaj që ka punuar:{' '}
                    <span className="highlight">
                      “Ai i ka krijuar jetën dhe vdekjen, për t’ju provuar se
                      kush prej jush do të veprojë më mirë; Ai është i
                      Plotfuqishmi dhe Falësi i madh.” Kuran, 67:2.
                    </span>{' '}
                    Por për ta adhuruar Atë, duhet ta njohim Atë mirë,
                    përndryshe mund të formojmë koncepte të shtrembëruara rreth
                    Tij dhe kështu të humbim rrugën. Në Kuran, Zoti i tregon
                    njerëzimit se çfarë Ai është dhe çfarë nuk është. Për
                    shembull, duke iu përgjigjur një pyetjeje rreth Zotit, që ia
                    kishin parashtruar profetit Muhamed, lëvdatat qofshin mbi
                    të, Zoti thotë:{' '}
                    <span className="highlight">
                      {' '}
                      “Thuaj (O Muhamed): ‘Ai është Allahu, Një dhe i Vetëm!
                      Allahu është Absoluti, të Cilit i përgjërohet gjithçka në
                      amshim. Ai as nuk lind, as nuk është i lindur. Dhe askush
                      nuk është i barabartë (a i krahasueshëm) me Atë!’” Kuran,
                      112.{' '}
                    </span>{' '}
                    Në konceptin Islam, Zoti nuk përngjan me qenien njerëzore
                    apo me diçka tjetër që ne mund ta imagjinojmë dhe Ai është i
                    vetmi që meriton të adhurohet. Nëse pajtohesh me atë që ke
                    lexuar deri më tani, atëherë të lutem lexoje artikullin
                    tjetër{' '}
                    <span className="highlight"> <Link to="/cfare-muslimanet-besojne"> “Si bëhesh musliman?”</Link></span>.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </section>
    </div>
  );
};

export default Reason;
