import React from 'react';
import Banner from '../components/Banner/Banner';
import Grid from '@mui/material/Grid';
import { Container } from '@mui/material';
import Heading from '../components/Heading/Heading';
import Box from '@mui/material/Box';
import Card from '../components/Card/Card';
import { useTranslation } from 'react-i18next';

import './IsLifeAGame.scss';

const GameOver = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="banner">
        <Banner />
        <div className="banner-header">
          <img src={require('../assets/images/gameover.png')} />
        </div>
      </div>
      <section>
        <Box>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={3}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Për një njeri të rëndomtë, vdekja në asnjë mënyrë nuk është
                    një çështje e këndshme apo temë e diskutimit. Kjo temë,
                    është dëshpëruese dhe e zymtë – vrasëse e vërtetë e
                    kënaqësive; temë që do t’i përshtatej vetëm një shtëpie
                    funeralesh.
                  </p>
                  <p className="description pt">
                    Njeriu i rëndomtë, ashtu i zhytur në vetën e tij, gjithmonë
                    në kërkim të gjërave të këndshme, gjithmonë në ndjekje të
                    gjërave që ia ngacmojnë dhe ia kënaqin epshin, refuzon të
                    ndalojë dhe të mendojë seriozisht se, pikërisht këto objekte
                    dëshirash dhe kënaqësish, një ditë të arrijnë fundin e tyre.
                  </p>
                  <p className="description pt">
                    Nëse këshillat e urta nuk na bindin dhe nuk na nxisin të
                    mendojmë seriozisht se vdekja edhe neve do të na troket një
                    ditë në derë, atëherë vetëm tronditja e ndonjë humbjeje të
                    madhe që do të na ndodhte nën kulmin tonë - si vdekja e
                    papritur dhe e parakohshme e njërit prind, gruas apo
                    fëmijëve - do të na kthjellonte nga jermi i ndjenjave të
                    kënaqësisë dhe do të na zgjonte në mënyrë të vrazhdë në
                    realitetin dhe faktet e hidhura të jetës.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid container marginTop={'4%'} justifyContent="flex-end">
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Vetëm atëherë do të na hapeshin sytë, vetëm atëherë do të
                    pyesnim “Pse ekziston një fenomen i tillë si vdekja?”; “Pse
                    është e pashmangshme?”; “Pse ekzistojnë këto ndarje të
                    dhimbshme, të cilat ia vjedhin lumturinë jetës?”Për shumicën
                    prej nesh, në disa momente të caktuara, skena të vdekjes
                    duhet të na kenë ngjallur mendime të thella dhe pyetje prej
                    më thelbësorëve.
                  </p>
                  <p className="description pt">
                    Çfarë vlere ka jeta, kur trupa të aftë të cilat më parë kanë
                    kryer punë të mëdha, tani qëndrojnë të shtrirë dhe të
                    ftohtë, pa ndjenja dhe pa jetë? Çfarë vlere ka jeta, kur
                    sytë të cilët më parë shkëlqenin nga hareja, sytë të cilët
                    më parë rrezatonin me dashuri, tani janë të mbyllur
                    përgjithmonë, të privuar nga të lëvizurit, të privuar nga
                    jeta?
                  </p>
                  <p className="description pt">
                    Mendime të këtilla, nuk duhet të mënjanohen! Janë mu këto
                    mendime kureshtare, të cilat nëse ndiqen me mençuri, na
                    shpalosin përfundimisht potencialin e natyrshëm në mendjen e
                    njeriut, për t’i perceptuar të vërtetat më të larta.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={'lg'}>
            <Grid
              container
              spacing={4}
              marginTop={'4%'}
              justifyContent="flex-start"
            >
              <Grid item xl={8} md={8} sm={12} xs={12}>
                <Card style="fifth">
                  <p className="description">
                    Bazuar në mënyrën islame të të menduarit, vdekja – që është
                    larg nga të qenit temë të cilën duhet shmangur apo
                    zhvlerësuar - është temë mbi të cilën duhet reflektuar dhe e
                    cila duhet të sjellë ndryshim pozitiv tek ne.
                  </p>
                  <p className="description pt">
                    Kurani, i cili është libri të cilin e besojnë muslimanët,
                    lidhur me këtë temë thotë:{' '}
                    <span className="highlight">
                      “Çdo njeri do ta shijojë vdekjen! Ne ju vëmë në provë me
                      të keqe dhe me të mirë dhe te Ne do të ktheheni.” 21:35
                    </span>
                  </p>
                  <p className="description pt">
                    Të reflektuarit mbi vdekjen e bën njeriun të mendojë për
                    qëllimin e tij në jetë. Për të shqyrtuar qëllimin tënd në
                    jetë, të lutem hape seksionin “Ngatërresat rreth Zotit” dhe
                    “Islami i shpalosur”.
                  </p>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </section>
    </div>
  );
};

export default GameOver;
