import { Box, Container, Grid } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Card from '../components/Card/Card';
import Header from '../components/Header/Header';

import './Layout.scss';

const Layout = (props) => {
  const { children } = props;
  return (
    <div className="layout">
      <div className="container">
        <Header />
        <div className="content">{children}</div>
        <footer>
          <a href="https://www.tijemusliman.com/namazi" target="_blank">
            <Card
              icon={<img src={require('../assets/images/pray.png')} />}
              style={'fourth'}
              description={'Mëso namazin'}
            />
          </a>
          <a href="https://pergjigje.net/" target="_blank">
            <Card
              icon={<img src={require('../assets/images/questionmark.png')} />}
              style={'fourth'}
              description={'Pyet rreth Islamit'}
            />
          </a>
          <a href="https://literaturaislame.com/" target="_blank">
            <Card
              icon={<img src={require('../assets/images/books.png')} />}
              style={'fourth'}
              description={'Literatura Islame'}
            />
          </a>
        </footer>
      </div>
    </div>
  );
};

export default Layout;
