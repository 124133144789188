import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './Dropdown.scss';

const Dropdown = (props) => {
  const { secondary } = props;
  const { t } = useTranslation();
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const menuData = [];

  if (!secondary) {
    menuData.push(
      {
        title: t('header.dropdown.isLifeJustAGame'),
        path: '/a-eshte-jeta-thjesht-loje',
        className: 'dropdown-link',
      },
      {
        title: t('header.dropdown.innerPeace'),
        path: '/rehatia-e-mbrendshme',
        className: 'dropdown-link',
      },
      {
        title: t('header.dropdown.gameOver'),
        path: '/gameover',
        className: 'dropdown-link',
      }
    );
  } else {
    menuData.push(
      {
        title: t('header.dropdown.whyIslam'),
        path: '/perse-islami',
        className: 'dropdown-link',
      },
      {
        title: t('header.dropdown.reason'),
        path: '/qellimi-sipas-islamit',
        className: 'dropdown-link',
      },
      {
        title: t('header.dropdown.belief'),
        path: '/cfare-muslimanet-besojne',
        className: 'dropdown-link',
      }
    );
  }

  const secondMenuData = [
    {
      title: t('header.dropdown.whyIslam'),
      path: '/perse-islami',
      className: 'dropdown-link',
    },
    {
      title: t('header.dropdown.reason'),
      path: '/qellimi-sipas-islamit',
      className: 'dropdown-link',
    },
    {
      title: t('header.dropdown.belief'),
      path: '/qellimi-sipas-islamit',
      className: 'dropdown-link',
    },
  ];

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? 'dropdown-menu clicked' : 'dropdown-menu'}
      >
        {menuData.map((i, k) => {
          return (
            <li key={k}>
              <Link
                className={i.className}
                to={i.path}
                onClick={() => setClick(false)}
              >
                {i.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default Dropdown;
