import React from 'react';

import './Heading.scss';

const Heading = (props) => {
  const { title } = props;
  return (
    <div className="heading">
      <h1>{title}</h1>
    </div>
  );
};

export default Heading;
