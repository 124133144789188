import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useResponsiveDimensions } from '../../hooks/useResponsiveDimensions';
import Dropdown from './Dropdown/Dropdown';

import './Header.scss';

const Header = () => {
  const { t } = useTranslation();
  const { isTablet } = useResponsiveDimensions();
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);

  const [mobileDropdown, setMobileDropdown] = useState(false);
  const [mobileSecondDropdown, setMobileSecondDropdown] = useState(false);

  const handleClick = () => setClick(!click);

  const handleToggleLogo = () => {
    if (isTablet) {
      setClick(false);
    }
  };

  const handleToggle = () => {
    if (isTablet) {
      setMobileDropdown(!mobileDropdown);
    }
  };

  const handleToggleSecondary = () => {
    if (isTablet) {
      setMobileSecondDropdown(!mobileSecondDropdown);
    }
  };

  const handleCloseMobileMenu = () => {
    setMobileDropdown(false);
    setMobileSecondDropdown(false);
    setClick(false);
  };

  const onHover = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const onHover2 = () => {
    if (window.innerWidth < 960) {
      setDropdown2(false);
    } else {
      setDropdown2(true);
    }
  };

  const onLeave2 = () => {
    if (window.innerWidth < 960) {
      setDropdown2(false);
    } else {
      setDropdown2(false);
    }
  };

  return (
    <div className="header">
      {/* <Link to="/" className="logo">
        <h1>BALLINA</h1>
      </Link> */}
      <div className="menu-icon" onClick={handleClick}>
        <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
      </div>
      <ul className={click ? 'nav-menu active' : 'nav-menu'}>
      <li
          className="nav-item"
          onClick={handleToggleLogo}
        >
          <Link
            className="nav-links"
            to="/"
          >
            BALLINA
          </Link>
        </li>
        <li
          className="nav-item"
          onClick={handleToggle}
          onMouseOver={onHover}
          onMouseLeave={onLeave}
        >
          
          <Link
            className="nav-links"
            onClick={handleToggle}
            onMouseOver={onHover}
          >
            {t('header.reasonOfLife')}
          </Link>
          {dropdown && <Dropdown />}
        </li>
        {mobileDropdown ? (
          <>
            <li className="nav-item mobile" onClick={handleCloseMobileMenu}>
              <Link className="nav-links" to={'/a-eshte-jeta-thjesht-loje'}>
                {t('header.dropdown.isLifeJustAGame')}
              </Link>
            </li>
            <li className="nav-item mobile" onClick={handleCloseMobileMenu}>
              <Link className="nav-links" to={'/rehatia-e-mbrendshme'}>
                {t('header.dropdown.innerPeace')}
              </Link>
            </li>
            <li className="nav-item mobile" onClick={handleCloseMobileMenu}>
              <Link className="nav-links" to={'/gameover'}>
                {t('header.dropdown.gameOver')}
              </Link>
            </li>
          </>
        ) : null}

        <li className="nav-item" onMouseOver={onHover2} onMouseLeave={onLeave2}>
          <Link
            className="nav-links"
            onClick={handleToggleSecondary}
            onMouseOver={onHover2}
            onMouseLeave={onLeave2}
          >
            {t('header.unfoldedIslam')}
          </Link>
          {dropdown2 && <Dropdown secondary />}
        </li>
        {mobileSecondDropdown ? (
          <>
            <li className="nav-item mobile" onClick={handleCloseMobileMenu}>
              <Link className="nav-links" to={'/perse-islami'}>
                {t('header.dropdown.whyIslam')}
              </Link>
            </li>
            <li className="nav-item mobile" onClick={handleCloseMobileMenu}>
              <Link className="nav-links" to={'/qellimi-sipas-islamit'}>
                {t('header.dropdown.reason')}
              </Link>
            </li>
            <li className="nav-item mobile" onClick={handleCloseMobileMenu}>
              <Link className="nav-links" to={'/cfare-muslimanet-besojne'}>
                {t('header.dropdown.belief')}
              </Link>
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};

export default Header;
